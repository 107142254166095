import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDataForTable } from 'store/modules/filters/tables.reducer';
import { useTableDataSelector } from 'store/modules/filters/tables.selector';

interface Props {
  row: any;
}

export const TableCheckbox = ({ row }: Props) => {
  const dispatch = useDispatch();
  let tableDispatch = useTableDataSelector();
  const [tableData, setTableData] = useState(tableDispatch);

  const settingTableData = (tableDispatch: any) => {
    setTableData(tableDispatch);
  };

  useEffect(() => {
    settingTableData(tableDispatch);
  }, [tableDispatch]);

  const checkId = (stream: any, id: any) => stream.id === id;

  const checkValue = (e: any, i: number, id: string) => {
    let checked: boolean[] = tableDispatch.checked.map((val) => val),
      streamIds: { id: string; frequency: string }[] = tableDispatch.stream.map(
        (val) => val,
      );
    checked[i] = e.target.checked;
    if (e.target.checked === true) {
      streamIds.push({ id: id, frequency: '' });
    } else {
      const index = streamIds.findIndex((stream) => checkId(stream, id));
      streamIds.splice(index, 1);
    }
    const payload = {
      checked: checked,
      stream: streamIds,
      initialStream: tableData.initialStream,
    };
    dispatch(setDataForTable(payload));
  };

  return (
    <Checkbox
      defaultChecked={tableData?.checked[row?.index]}
      onChange={(e) => checkValue(e, row.index, row.id)}
    />
  );
};
