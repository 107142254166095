import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitDetails: () => void;
  connection: any;
  setConnection: any;
  connectorTypes: string[];
}

export function StreamDialog({
  setOpen,
  submitDetails,
  setConnection,
  connection,
  connectorTypes,
}: Props) {
  return (
    <Box width="400px">
      <DialogTitle>Stream Details</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Name"
          sx={{ mt: 1 }}
          value={connection.stream}
          onChange={(e) => {
            setConnection((prev: any) => ({
              ...prev,
              stream: e.target.value,
            }));
          }}
        />
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Connector Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Connector Type"
            value={connection?.connector_type?.toLowerCase()}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                connector_type: e.target.value,
              }));
            }}
          >
            {connectorTypes.map((src) => {
              return (
                <MenuItem key={src} value={src}>
                  {src}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label-1">
            Deployment Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-1"
            label="Deployment Type"
            value={connection.deployment_type}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                deployment_type: e.target.value,
              }));
            }}
          >
            {['Server', 'Cloud'].map((src, index) => {
              return (
                <MenuItem key={src} value={src.toUpperCase()}>
                  {src}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label-2">Tier</InputLabel>
          <Select
            labelId="demo-simple-select-label-2"
            label="Tier"
            value={connection.tier}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                tier: e.target.value,
              }));
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((src, index) => {
              return (
                <MenuItem key={src} value={src}>
                  {src}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box>
          <TextField
            label="Params"
            fullWidth
            sx={{ mt: 2 }}
            value={
              typeof connection.params === 'string'
                ? connection.params
                : connection.params.join(',')
            }
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                params: e.target.value,
              }));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={2}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={submitDetails}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
}
