import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import PaginationComponent from 'components/pagination/Pagination';
import { HistoryDetails } from 'store/apis/types';

export function ExecutionDetails() {
  const { tenant, connection, type, execution } = useParams();
  const ApiProvider = useContext(ApiContext);

  const [page, setPage] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsData, setRowsData] = useState<HistoryDetails[] | undefined>();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const [getConnectionHistoryDetailsData] =
    ApiProvider.baseApi?.useLazyGetExecutionDetailsQuery();

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPageNo(value - 1);
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const data = await getConnectionHistoryDetailsData({
      tenantId: tenantHash,
      connectorType: type,
      connectionId: connection,
      executionId: execution,
      params: {
        items_page: pageEntryTotal,
        page: pageNo,
      },
    });
    setRowsData(() => data?.data?.data);
    if (!totalPage) setTotalPage(Number(data?.data?.total));
  };

  const {
    data: historyDetailsData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetExecutionDetailsQuery({
    tenantId: tenantHash,
    connectorType: type,
    connectionId: connection,
    executionId: execution,
  });

  useEffect(() => {
    if (!rowsData) {
      onFilterChange(1, 10);
    }
    setRowsData(() => historyDetailsData?.data);
    setTotalPage(historyDetailsData?.total);
  }, [historyDetailsData]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 220,
      editable: true,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.date).format('MMMM Do YYYY, h:mm:ss a')}
          </Box>
        );
      },
    },
    {
      field: 'deploymentType',
      headerName: 'Deployment Type',
      width: 150,
    },
    {
      field: 'stream',
      headerName: 'Stream',
      width: 240,
    },
    {
      field: 'requestID',
      headerName: 'Request ID',
      width: 320,
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 320,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            {params?.row?.request_message?.message || ''}
          </Box>
        );
      },
    },
    {
      field: 'projectId',
      headerName: 'Request Details',
      width: 480,
      renderCell: (params) => {
        return (
          <Box>
            {Object.entries(params?.row?.requestDetails).map((entry) => (
              <Box key={entry[0]}>
                <Typography>
                  {' '}
                  {entry[0]} : {typeof entry[1] === 'string' ? entry[1] : ''}{' '}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {rowsData?.length ? (
        <Box sx={{ height: '71vh', width: '81vw' }}>
          <DataGrid
            rows={rowsData.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            rowHeight={120}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
    </ContainerWithLoader>
  );
}
