import { GridColDef } from '@mui/x-data-grid';
import { TableCheckbox } from 'components/table-checkbox/table-checkbox';
// import { TableFrequency } from 'components/table-frequency/table-frequency';

export const streamsColumns: GridColDef[] = [
  {
    field: 'action',
    width: 80,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (param) => {
      return <TableCheckbox row={param.row} />;
    },
    headerName: 'Select',
  },
  {
    field: 'stream',
    headerName: 'Stream',
    width: 420,
  },
  {
    field: 'deployment_type',
    headerName: 'Deployment Type',
    width: 200,
  },
  {
    field: 'connector_type',
    headerName: 'Connector Type',
    width: 200,
  },
  {
    field: 'tier',
    headerName: 'Tier',
    width: 80,
  },
  // {
  //   field: 'cron',
  //   flex: 1,
  //   disableColumnMenu: true,
  //   sortable: false,
  //   renderCell: (param) => {
  //     return <TableFrequency row={param.row} />;
  //   },
  //   headerName: 'Frequency',
  // },
];
