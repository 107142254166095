import React from 'react';
import { Box, Button, DialogTitle, DialogActions } from '@mui/material';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitDelete: () => void;
  deleteItem: string;
}

export function DeletionDialog({ setOpen, submitDelete, deleteItem }: Props) {
  return (
    <Box>
      <DialogTitle>
        Do you want to delete <b>{deleteItem}</b> ?
      </DialogTitle>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={1}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={submitDelete} autoFocus>
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
}
