import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import AddIcon from '@mui/icons-material/Add';
import { GlobalStreamDialog } from './stream-dialog/stream-dialog';
import PaginationComponent from 'components/pagination/Pagination';
import { StreamData } from 'store/apis/types';
import { DeletionDialog } from 'components/delete-modal/delete-modal';
import DeleteIcon from '@mui/icons-material/Delete';

export function GlobalStreams() {
  const ApiProvider = useContext(ApiContext);
  const generateBaseStream = () => {
    return {
      connector_type: '',
      deployment_type: '',
      params: '',
      stream: '',
      tier: 1,
      disabled: true,
    };
  };

  const [tier, setTier] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [deployment, setDeployment] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [connectorType, setConnectorType] = useState('');
  const [stream, setStream] = useState(generateBaseStream());
  const [rowsData, setRowsData] = useState<StreamData[] | undefined>();

  const [getStream] = ApiProvider.baseApi?.useLazyGetGlobalStreamsQuery();

  const [createStream] = ApiProvider.baseApi?.useAddGlobalStreamMutation();

  const [deleteStream] = ApiProvider.baseApi?.useDeleteGlobalStreamMutation();

  const { data: connectorsData } =
    ApiProvider.baseApi?.useGetGlobalConnectorTypesQuery({});

  const addStream = () => {
    setStream(generateBaseStream());
    setOpen(true);
  };

  const submitDetails = async () => {
    if (stream?.connector_type && stream?.stream && stream?.tier) {
      if (stream?.deployment_type) {
        const res = await createStream([
          { ...stream, params: stream.params.split(',') },
        ]);
        console.log('stream add response', res);
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    pageChange(null, 1);
    onFilterChange(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorType, tier, deployment]);

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const params: any = {
      items_page: pageEntryTotal,
      page: pageNo,
    };
    if (connectorType) params.connector_type = connectorType;
    if (deployment) params.deployment_type = deployment;
    if (tier) params.tier_filter = tier;
    const globalStreams = await getStream(params);
    setRowsData(() => globalStreams?.data?.data);
    setTotalPage(Number(globalStreams?.data?.total));
  };

  const deleteDialog = (params: any) => {
    setStream(params);
    setOpenDelete(true);
  };

  const deleteClick = async () => {
    await deleteStream({
      deployment_type: stream.deployment_type,
      connector_type: stream.connector_type,
      stream: stream.stream,
    });
    setOpenDelete(false);
    pageChange(null, 1);
    onFilterChange(1, 10);
  };

  const columns: GridColDef[] = [
    {
      field: 'stream',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'tier',
      headerName: 'Tier',
      sortable: false,
      width: 50,
    },
    {
      field: 'params',
      headerName: 'Params',
      width: 240,
      renderCell: (params) => {
        return <Box>{params?.row?.params[0]}</Box>;
      },
    },
    {
      field: 'connector_type',
      headerName: 'Connector Type',
      width: 180,
      editable: true,
    },
    {
      field: 'deployment_type',
      headerName: 'Deployment Type',
      width: 130,
    },
    {
      field: 'agent_id',
      headerName: 'Action',
      width: 90,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Tooltip title="Delete Global Stream">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => deleteDialog(params.row)}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={!rowsData} isError={!rowsData}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" gap={1}>
          <Box width="200px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">
                Connector Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-1"
                label="Connector Type"
                value={connectorType}
                onChange={(e) => {
                  setConnectorType(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {connectorsData?.data.map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width="80px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">Tier</InputLabel>
              <Select
                labelId="tisser-101"
                label="Tier"
                value={tier}
                onChange={(e) => {
                  setTier(Number(e.target.value) || 0);
                }}
              >
                <MenuItem value={0}>None</MenuItem>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width="200px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">
                Deployment Type
              </InputLabel>
              <Select
                labelId="dpelotment-1"
                label="Deployment Type"
                value={deployment}
                onChange={(e) => {
                  setDeployment(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {['Server', 'Cloud'].map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={addStream}
          >
            Add Global Stream
          </Button>
        </Box>
      </Box>
      {rowsData?.length ? (
        <Box sx={{ height: '62vh', width: '100%' }}>
          <DataGrid
            rows={rowsData?.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <GlobalStreamDialog
          setOpen={setOpen}
          connection={stream!}
          setConnection={setStream}
          connectorTypes={connectorsData?.data}
          submitDetails={submitDetails}
        />
      </Dialog>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DeletionDialog
          setOpen={() => setOpenDelete(false)}
          deleteItem={stream?.stream}
          submitDelete={deleteClick}
        />
      </Dialog>
    </ContainerWithLoader>
  );
}
