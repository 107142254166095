import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PaginationComponent from 'components/pagination/Pagination';
import { HistoryData } from 'store/apis/types';

export function ConnectionHistory() {
  const navigate = useNavigate();
  const { tenant, connection, type } = useParams();
  const ApiProvider = useContext(ApiContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsData, setRowsData] = useState<HistoryData[] | undefined>();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: connectionHistoryData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetConnectionHistoryQuery({
    tenantId: tenantHash,
    connectorType: type,
    connectionId: connection,
  });

  useEffect(() => {
    if (!rowsData) {
      onFilterChange(1, 10);
    }
    setRowsData(() => connectionHistoryData?.data);
    setTotalPage(connectionHistoryData?.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionHistoryData]);

  const [getConnectionHistoryData] =
    ApiProvider.baseApi?.useLazyGetConnectionHistoryQuery();

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (
    pageNo: number,
    pageEntryTotal: number,
    sort?: GridSortModel,
  ) => {
    const params: any = {
      items_page: pageEntryTotal,
      page: pageNo,
    };
    if (sort && sort?.length && sort[0]?.field) {
      params.sort_by = (sort[0]?.sort === 'asc' ? '+' : '-') + sort[0]?.field;
    }
    const data = await getConnectionHistoryData({
      tenantId: tenantHash,
      connectorType: type,
      connectionId: connection,
      params,
    });
    setRowsData(() => data?.data?.data);
    if (!totalPage) setTotalPage(Number(data?.data?.total));
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    onFilterChange(page, pageSize, sortModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      width: 220,
      editable: true,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.date).format('MMMM Do YYYY, h:mm:ss a')}
          </Box>
        );
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 80,
    },
    {
      field: 'total_successful',
      headerName: 'Successful',
      width: 90,
      editable: true,
    },
    {
      field: 'total_failed',
      headerName: 'Failed',
      width: 90,
      editable: true,
    },
    {
      field: 'total_pending',
      headerName: 'Pending',
      width: 90,
      editable: true,
    },
    {
      field: 'total_dropped',
      headerName: 'Dropped',
      width: 90,
      editable: true,
    },
    {
      field: 'executionID',
      headerName: 'Execution ID',
      flex: 1,
      editable: true,
    },
    {
      field: 'abc',
      headerName: 'Details',
      width: 90,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '50%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/tenant/${tenant}/${type}/${connection}/history/${params?.row?.executionID}/details`,
                )
              }
            />
          </Box>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {rowsData?.length ? (
        <Box sx={{ height: '71vh', width: '100%' }}>
          <DataGrid
            rows={rowsData.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            columns={columns}
            disableRowSelectionOnClick
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
    </ContainerWithLoader>
  );
}
