import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import PaginationComponent from 'components/pagination/Pagination';
import { StreamData } from 'store/apis/types';

export function Diagnostics() {
  const { tenant } = useParams();
  const ApiProvider = useContext(ApiContext);

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [currentCommand, setCurrentCommand] = useState('');
  const [rowsData, setRowsData] = useState<StreamData[] | undefined>();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  const [getDiagnostics] =
    ApiProvider.baseApi?.useLazyGetTenantDiagnosticsQuery();

  const [addDiagnostics] = ApiProvider.baseApi?.useAddDiagnosticsMutation();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  useEffect(() => {
    onFilterChange(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantHash]);

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const filteredData = await getDiagnostics({
      tenantId: tenantHash || '',
      params: {
        items_page: pageEntryTotal,
        page: pageNo,
      },
    });
    setRowsData(() => filteredData?.data?.data);
    if (!totalPage) setTotalPage(Number(filteredData?.data?.total));
  };

  const addDiagnostic = async () => {
    const response = await addDiagnostics({
      tenantId: tenantHash,
      command: currentCommand,
    });
    onFilterChange(1, 10);
    console.log(response, 'add diagnostic response');
    setOpen(false);
    setCurrentCommand('');
  };

  const columns: GridColDef[] = [
    {
      field: 'diagnostics_id',
      headerName: 'Diagnostic ID',
      width: 290,
    },
    {
      field: 'agent_id',
      headerName: 'Agent ID',
      sortable: false,
      width: 120,
    },
    {
      field: 'command',
      headerName: 'Command',
      width: 270,
      editable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 220,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
          </Box>
        );
      },
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 90,
      renderCell: (params) => {
        return <Box>{params?.row?.result || params?.row?.response}</Box>;
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={!rowsData} isError={!rowsData}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        sx={{ mb: 2, float: 'right' }}
      >
        Add Diagnostics
      </Button>
      {rowsData?.length ? (
        <Box sx={{ height: '71vh', width: '100%' }}>
          <DataGrid
            rows={rowsData?.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            columns={columns}
            hideFooter
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />
          <Box display="flex" justifyContent="end" mt={2}>
            <PaginationComponent
              count={Math.ceil(totalPage / pageSize)}
              page={page}
              pageChange={pageChange}
              pageSize={pageSize}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Box>
        </Box>
      ) : null}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent sx={{ width: '600px' }}>
          <DialogContentText>Add Diagnostic</DialogContentText>
          <TextField
            label="Command"
            multiline
            fullWidth
            sx={{ mt: 2 }}
            value={currentCommand}
            onChange={(e) => {
              setCurrentCommand(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            px={2}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
                setCurrentCommand('');
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => addDiagnostic()}
              autoFocus
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ContainerWithLoader>
  );
}
