import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {},
  palette: {},
  components: {
    MuiInputBase: {},
    MuiMenu: {},
  },
});
