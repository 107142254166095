import { ReactNode } from 'react';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import StreamIcon from '@mui/icons-material/Stream';
import LogoutIcon from '@mui/icons-material/Logout';
import { paths } from 'constants/routes';

export interface NavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
  activePaths?: string[];
  children?: ChildNavItemProps[];
  icon: () => ReactNode;
  bottomItem?: boolean;
}

export interface ChildNavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
}

export const navItems: NavItemProps[] = [
  {
    id: 'connectors',
    path: 'connectors',
    title: 'Connectors',
    icon: () => <AccountTreeIcon />,
  },
  {
    id: 'streams',
    path: 'streams',
    title: 'Tenant Streams',
    icon: () => <StreamIcon />,
  },
  {
    id: 'diagnostics',
    path: 'diagnostics',
    title: 'Diagnostics',
    icon: () => <HealthAndSafetyIcon />,
  },
  {
    id: 'globalStreams',
    path: 'globalStreams',
    title: 'Global Streams',
    icon: () => <StreamIcon />,
  },
  {
    id: 'versions',
    path: 'versions',
    title: 'Versions',
    icon: () => <AllInclusiveIcon />,
  },
  {
    //status-check
    id: 'tenants',
    path: 'tenants',
    title: 'Status Check',
    icon: () => <StreamIcon />,
  },
  {
    id: 'logout',
    path: 'logout',
    title: 'Logout',
    icon: () => <LogoutIcon />,
  },
];
