import React from 'react';
import { Box, Button, DialogTitle, DialogActions } from '@mui/material';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitFunction: () => void;
  message: string;
}

export function ConfirmationDialog({
  setOpen,
  submitFunction,
  message,
}: Props) {
  return (
    <Box>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={1}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={submitFunction} autoFocus>
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
}
