import { useContext, useState } from 'react';
import cronstrue from 'cronstrue';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Alert, Box, Button, Dialog, Snackbar, Tooltip } from '@mui/material';
import {
  DialogContent,
  DialogTitle
} from '@mui/material';

import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { useTableDataSelector } from 'store/modules/filters/tables.selector';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { setDataForTable } from 'store/modules/filters/tables.reducer';
import { DeletionDialog } from 'components/delete-modal/delete-modal';
import { ScheduleDialog } from './schedule-dialog/schedule-dialog';
import { StreamData } from 'store/apis/types';

export function ConnectionSchedules() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ApiProvider = useContext(ApiContext);
  const { tenant, connection, type, deployment_type } = useParams();
  const generateBaseSchedule = () => {
    return {
      schedule_name: '',
      schedule_id: '',
      connector_type: '',
      connection_id: '',
      stream_list: [],
      cron_string: '',
      tierSelection: 0,
      run_analytics: false,
      enabled: true
    };
  };
  let tableDispatch = useTableDataSelector();

  const [open, setOpen] = useState(false);
  const [getOpen, setGetOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [triggered, setTriggered] = useState(false);
  const [eventData, setEventBridgeData] = useState({});
  const [currentSchedule, setCurrentSchedule] = useState(
    generateBaseSchedule(),
  );
  const [tierSelection, setTierSelection] = useState(0);

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: streamsData,
    isError: streamsError,
    isLoading: streamsLoading,
  } = ApiProvider.baseApi?.useGetTenantStreamsQuery({
    tenantId: tenantHash,
    params: {
      items_page: 100000,
      page: 1,
      disabled: false,
      connector_type: type,
      deployment_type: deployment_type,
    },
  });

  const [triggerSelectedSchedule] =
    ApiProvider.baseApi?.useLazyTriggerScheduleQuery();

  const [createSchedule] = ApiProvider.baseApi?.useCreateScheduleMutation();

  const [updateSchedule] = ApiProvider.baseApi?.useUpdateScheduleMutation();

  const [deleteSchedule] = ApiProvider.baseApi?.useDeleteScheduleMutation();

  const [getSchedule] = ApiProvider.baseApi?.useLazyGetScheduleQuery();

  const [getEventBridgeSchedule] = ApiProvider.baseApi?.useLazyGetEventBridgeScheduleQuery();

  const [changeStatusSchedule] = ApiProvider.baseApi?.useChangeStatusScheduleMutation();

  const handleGetClose = () => {
    setGetOpen(false);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const addSchedule = () => {
    dispatchStreams([]);
    setCurrentSchedule(generateBaseSchedule());
    setOpen(true);
  };

  const editSchedule = (params: any) => {
    console.log(params);
    dispatchStreams(params?.stream_list);
    setCurrentSchedule(params);
    setTierSelection(params.tier)
    setOpen(true);
  };

  const getAddedStream = () => {
    const addedStreams = tableDispatch.stream;
    const addedStreamObject = streamsData?.data
      .map((item: any, index: number) => {
        return {
          ...item,
          id: `${item.stream}-${item?.tier}`,
          index: index,
        };
      })
      .filter((str: any) => addedStreams.find((upd: any) => upd.id === str.id));
    const finalAddedata = addedStreamObject.map(
      ({ id, index, ...rest }: any) => rest,
    );
    return finalAddedata.map((data: any, index: number) => {
      return {
        ...data,
        enabled: true,
      };
    });
  };

  const submitDetails = async () => {

    const payload = tierSelection > 0 ? { ...currentSchedule, tier: tierSelection, stream_list: [] } : { ...currentSchedule, stream_list: getAddedStream() }
    if (tierSelection > 0) payload.stream_list = [];
    if (!currentSchedule?.schedule_id) {
      const res = await createSchedule({
        tenantId: tenantHash,
        connectorType: type,
        connectionId: connection,
        payload
      });
      console.log('schedule add response', res);
    } else {
      const res = await updateSchedule({
        tenantId: tenantHash,
        connectorType: type,
        connectionId: connection,
        scheduleId: currentSchedule?.schedule_id,
        payload
      });
      console.log('schedule edit response', res);
    }
    setTierSelection(0);
    handleDialogClose();
  };

  const deleteClick = async () => {
    await deleteSchedule({
      tenantId: tenantHash,
      connectorType: type,
      connectionId: connection,
      scheduleId: currentSchedule?.schedule_id,
    });
    setDeleteOpen(false);
  };

  const dispatchStreams = (streamList: StreamData[]) => {
    const arr = streamList?.map(
      (stream: any) => `${stream.stream}-${stream.tier}`,
    );
    const arr1 = streamList?.map((stream: any) => {
      return {
        id: `${stream.stream}-${stream.tier}`,
        frequency: stream.cron_string,
      };
    });
    const payload = {
      checked: streamsData?.data
        .map((item: any, index: number) => {
          return {
            ...item,
            id: `${item.stream}-${item?.tier}`,
            index: index,
          };
        })
        .map((row: any) => arr?.includes(row.id)),
      stream: arr1,
      initialStream: arr1,
    };
    dispatch(setDataForTable(payload));
  };

  const openDeleteDialog = (selectedSchedule: any) => {
    setCurrentSchedule(selectedSchedule);
    setDeleteOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'schedule_name',
      headerName: 'Schedule Name  ',
      width: 300,
    },
    {
      field: 'schedule_id',
      headerName: 'Schedule ID  ',
      width: 360,
    },
    {
      field: 'cron_string',
      headerName: 'Frequency',
      width: 300,
      renderCell: (params) => {
        return <Box>{cronstrue.toString(params.row.cron_string)}</Box>;
      },
    },
    {
      field: 'enabled',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        if (params.row.enabled == false) {
          return <Box>Disabled</Box>
        } else {
          return <Box>Enabled</Box>
        }
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => {
        return (
          <Box display="flex" width="100%" justifyContent="space-around">
            {params.row.enabled === false ? (
              <Tooltip title="Enable">
                <EventAvailableIcon
                  color="success"
                  sx={{ cursor: 'pointer' }}
                  onClick={async () => {
                    const payload = {
                      "enabled": true,
                      "tier": params?.row.tier,
                      "cron_string": params?.row.cron_string,
                      "schedule_name": params?.row.schedule_name,
                      "run_analytics": params?.row.run_analytics,
                      "stream_list": params?.row.stream_list
                    }
                    if (params?.row.tier > 0) payload.stream_list = [];
                    const responseData = await changeStatusSchedule({
                      tenantId: tenantHash!,
                      connectorType: type,
                      connectionId: connection,
                      scheduleId: params.row.schedule_id,
                      payload
                    });
                  }}
                />

              </Tooltip>
            ) : (
              <Tooltip title="Disable">
                <EventBusyIcon
                  color="warning"
                  sx={{ cursor: 'pointer' }}
                  onClick={async () => {
                    const payload = {
                      "enabled": false,
                      "tier": params?.row.tier,
                      "cron_string": params?.row.cron_string,
                      "schedule_name": params?.row.schedule_name,
                      "run_analytics": params?.row.run_analytics,
                      "stream_list": params?.row.stream_list
                    }
                    if (params?.row.tier > 0) payload.stream_list = [];
                    const responseData = await changeStatusSchedule({
                      tenantId: tenantHash!,
                      connectorType: type,
                      connectionId: connection,
                      scheduleId: params.row.schedule_id,
                      payload
                    });
                  }}
                />
              </Tooltip>
            )}
            <Tooltip title="View AWS Event Schedule">
              <HistoryToggleOffIcon
                color="success"
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  console.log(params.row.schedule_id);
                  const responseData = await getEventBridgeSchedule({
                    tenantId: tenantHash!,
                    connectorType: type,
                    connectionId: connection,
                    schedule_id: params.row.schedule_id,
                  });
                  setEventBridgeData(responseData);
                  setGetOpen(true);
                  if (responseData?.status) {
                    setTriggered(true);
                    setSnackMessage(responseData?.data?.status);
                  } else {
                    setTriggered(true);
                    setSnackMessage('Something went wrong please try again');
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Trigger">
              <UpdateIcon
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const responseData = await triggerSelectedSchedule({
                    tenantId: tenantHash!,
                    connectorType: type,
                    connectionId: connection,
                    scheduleId: params.row.schedule_id,
                  });
                  if (responseData?.status) {
                    setTriggered(true);
                    setSnackMessage(responseData?.data?.status);
                  } else {
                    setTriggered(true);
                    setSnackMessage('Something went wrong please try again');
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => openDeleteDialog(params?.row)}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'ss',
      headerName: 'Streams List',
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/tenant/${tenant}/${type}/${connection}/schedules/${params?.row?.schedule_id}/streams`,
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <EditIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() => editSchedule(params.row)}
            />
          </Box>
        );
      },
    },
  ];

  const {
    data: schedulesData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetConnectionSchedulesQuery({
    tenantId: tenantHash,
    connectorType: type,
    connectionId: connection,
  });

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={addSchedule}
        sx={{ mb: 2, float: 'right' }}
        disabled={streamsLoading || streamsError}
      >
        Add Schedule
      </Button>
      <Box sx={{ height: '71vh', width: '100%' }}>
        <DataGrid
          rows={
            schedulesData?.data.length === 0
              ? []
              : schedulesData?.data?.map((item: any, index: number) => {
                return {
                  ...item,
                  id: index + 1,
                };
              })
          }
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          columns={columns}
          disableRowSelectionOnClick
          sx={{ background: '#fff', width: '100%' }}
        />
      </Box>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={handleDialogClose}>
        <ScheduleDialog
          setOpen={setOpen}
          allStreams={streamsData?.data}
          submitDetails={submitDetails}
          setSchedule={setCurrentSchedule}
          scheduleDetails={currentSchedule!}
          tierSelection={tierSelection}
          setTierSelection={setTierSelection}
        />
      </Dialog>
      <Dialog open={getOpen} maxWidth="lg" fullWidth onClose={handleGetClose}>
        <Box>
          <DialogTitle>AWS Event Bridge Schedule Details</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <div><pre>{JSON.stringify(eventData, null, 2)}</pre></div>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DeletionDialog
          setOpen={setDeleteOpen}
          deleteItem={schedulesData?.schedule_id}
          submitDelete={deleteClick}
        />
      </Dialog>
      <Snackbar
        open={triggered}
        autoHideDuration={3000}
        onClose={() => setTriggered(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          severity={'success'}
          sx={{ width: '100%' }}
          onClose={() => setTriggered(false)}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </ContainerWithLoader>
  );
}
