import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const generateBaseApi = (
  getAccessTokenSilently: () => Promise<string>,
) =>
  createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: async (headers) => {
        const token = await getAccessTokenSilently();
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      }
    },
    ),
    tagTypes: [
      'GetTenants',
      'GetTenantConnectors',
      'GetConnectorStreams',
      'GetConnectionHistory',
      'GetConnections',
      'AddConnection',
      'GetConnectionSpecificStreams',
      'GetConnectionSchedules',
      'GetExecutionDetails',
      'TriggerSchedule',
      'GetConnectionTasks',
      'GetAgentVersions',
      'GetConnectorVersions',
      'GetTenantDiagnostics',
      'GetTenantConnectorTypes',
      'GetGlobalStreams',
      'GetGlobalConnectorVersions',
      'GetTenantStreams',
      'GetGlobalConnectorTypes',
      'GetTenantSecrets',
      'GetTenantVersion',
      'GetMonitoringTenants',
      'GetMonitoringTenantsData',
      'GetIgnoredData'
    ],
    endpoints: (builder) => ({
      getGlobalStreams: builder.query<any, any>({
        query: (params) => ({
          url: 'get-all-streams',
          params,
        }),
        providesTags: ['GetGlobalStreams'],
      }),
      addGlobalStream: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/bulk-add-global-streams`,
          method: 'POST',
          body: { stream_list: payload },
        }),
        invalidatesTags: ['GetGlobalStreams'],
      }),
      deleteGlobalStream: builder.mutation<any, any>({
        query: (params) => ({
          url: `/delete-global-stream`,
          params,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetGlobalStreams'],
      }),
      replaceGlobalStream: builder.mutation<any, any>({
        query: ({ tenantId, params }) => ({
          url: `${tenantId}/replace-streams-with-global-streams`,
          params,
          method: 'PUT',
        }),
        invalidatesTags: ['GetGlobalStreams'],
      }),
      getTenantVersion: builder.query<any, String>({
        query: (tenantId) => ({
          url: `${tenantId}/agent-info`,
        }),
        providesTags: ['GetTenantVersion'],
      }),
      disableTenantStreams: builder.mutation<any, any>({
        query: ({ tenantId, params }) => ({
          url: `${tenantId}/disable-all-streams`,
          params,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetGlobalStreams'],
      }),
      getGlobalConnectorVersions: builder.query<any, any>({
        query: (params) => ({
          url: 'get-all-versions',
          params,
        }),
        providesTags: ['GetGlobalConnectorVersions'],
      }),
      getGlobalConnectorTypes: builder.query<any, any>({
        query: (params) => ({
          url: `get-all-connector-types`,
        }),
        providesTags: ['GetGlobalConnectorTypes'],
      }),
      getTenants: builder.query<any, void>({
        query: () => ({
          url: 'tenants',
        }),
        providesTags: ['GetTenants'],
      }),
      getTenantStreams: builder.query<any, any>({
        query: ({ tenantId, params }) => ({
          url: `${tenantId}/get-streams`,
          params,
        }),
        providesTags: ['GetTenantStreams'],
      }),
      getTenantConnectors: builder.query<any, any>({
        query: ({ tenantId, agent_id }) => ({
          url: `${tenantId}/connectors-agent/${agent_id}`,
        }),
        providesTags: ['GetTenantConnectors'],
      }),
      getTenantConnectorTypes: builder.query<any, string>({
        query: (tenantId) => ({
          url: `${tenantId}/get-all-connector-types`,
        }),
        providesTags: ['GetTenantConnectorTypes'],
      }),
      getTenantDiagnostics: builder.query<
        any,
        { tenantId: string; params?: Record<string, any> | undefined }
      >({
        query: ({ tenantId, params }) => ({
          url: `${tenantId}/diagnostics`,
          params,
        }),
        providesTags: ['GetTenantDiagnostics'],
      }),
      getTenantSecrets: builder.query<
        any,
        { tenantId: string; params?: Record<string, any> | undefined }
      >({
        query: ({ tenantId, params }) => ({
          url: `${tenantId}/secrets`,
          params,
        }),
        providesTags: ['GetTenantSecrets'],
      }),
      addDiagnostics: builder.mutation<any, any>({
        query: ({ tenantId, command }) => ({
          url: `${tenantId}/diagnostics`,
          method: 'POST',
          body: {
            command,
          },
        }),
        invalidatesTags: ['GetTenantDiagnostics'],
      }),
      addConnector: builder.mutation<any, any>({
        query: ({ tenantId, payload }) => ({
          url: `${tenantId}/connectors`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetTenantConnectors'],
      }),
      updateConnector: builder.mutation<any, any>({
        query: ({ tenantId, payload }) => ({
          url: `${tenantId}/connectors`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['GetTenantConnectors'],
      }),
      deleteConnector: builder.mutation<any, any>({
        query: ({ tenantId, connectorType }) => ({
          url: `${tenantId}/connectors/${connectorType}/delete`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetTenantConnectors'],
      }),
      getAgentVersions: builder.query<any, string>({
        query: (tenantId) => ({
          url: `get-agent-versions`,
        }),
        providesTags: ['GetAgentVersions'],
      }),
      updateTenantVersion: builder.mutation<any, any>({
        query: ({ tenantId, version, agent_id }) => ({
          url: `${tenantId}/update-agent-version`,
          method: 'POST',
          body: { version: version, agent_id: agent_id },
        }),
        invalidatesTags: ['GetTenantConnectors', 'GetAgentVersions', 'GetTenantVersion'],
      }),
      getConnections: builder.query<any, any>({
        query: ({ tenantId, connectorType }) => ({
          url: `${tenantId}/connectors/${connectorType}`,
        }),
        providesTags: ['GetConnections'],
      }),
      getConnectorStreams: builder.query<any, any>({
        query: ({ tenantId, connectorType, params }) => ({
          url: `${tenantId}/connectors/${connectorType}/stream`,
          params,
        }),
        providesTags: ['GetConnectorStreams'],
      }),
      getConnectorVersions: builder.query<any, any>({
        query: ({ connectorType, params }) => ({
          url: `connectors/${connectorType}/get-connector-versions`,
        }),
        providesTags: ['GetConnectorVersions'],
      }),
      getConnectionTasks: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId, params }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/get-tasks`,
          params,
        }),
        providesTags: ['GetConnectionTasks'],
      }),
      getConnectionHistory: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId, params }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/history`,
          params,
        }),
        providesTags: ['GetConnectionHistory'],
      }),
      addConnection: builder.mutation<any, any>({
        query: ({ tenantId, payload }) => ({
          url: `${tenantId}/connectors/${payload?.connector_type}/connections/add`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnections'],
      }),
      updateConnection: builder.mutation<any, any>({
        query: ({ tenantId, connectorType, connectionId, payload }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/update-connection-details`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnections'],
      }),
      deleteConnection: builder.mutation<any, any>({
        query: ({ tenantId, connectorType, connectionId }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/delete`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetConnections'],
      }),
      updateAllConnectorVersions: builder.mutation<any, any>({
        query: ({ connectorType, version }) => ({
          url: `connectors/${connectorType}/update-all-connector-versions`,
          method: 'PUT',
          body: { version },
        }),
        invalidatesTags: ['GetGlobalConnectorVersions'],
      }),
      getConnectionSchedules: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/get-schedules`,
        }),
        providesTags: ['GetConnectionSchedules'],
      }),
      getExecutionDetails: builder.query<any, any>({
        query: ({
          tenantId,
          connectorType,
          connectionId,
          executionId,
          params,
        }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/${executionId}/details`,
          params,
        }),
        providesTags: ['GetExecutionDetails'],
      }),
      triggerSchedule: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId, scheduleId }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${scheduleId}/trigger`,
        }),
        providesTags: ['TriggerSchedule'],
      }),
      createSchedule: builder.mutation<any, any>({
        query: ({ tenantId, connectorType, connectionId, payload }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/create-schedule`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnectionSchedules'],
      }),
      updateSchedule: builder.mutation<any, any>({
        query: ({
          tenantId,
          connectorType,
          connectionId,
          scheduleId,
          payload,
        }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${scheduleId}/update`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnectionSchedules'],
      }),
      deleteSchedule: builder.mutation<any, any>({
        query: ({ tenantId, connectorType, connectionId, scheduleId }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${scheduleId}/remove`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetConnectionSchedules'],
      }),
      getSchedule: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId, schedule_id, payload }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${schedule_id}`,
          method: 'GET',
          body: payload,
        }),
      }),
      getEventBridgeSchedule: builder.query<any, any>({
        query: ({ tenantId, connectorType, connectionId, schedule_id, payload }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${schedule_id}/event_bridge`,
          method: 'GET',
          body: payload,
        }),
      }),
      changeStatusSchedule: builder.mutation<any, any>({
        query: ({
          tenantId,
          connectorType,
          connectionId,
          scheduleId,
          payload,
        }) => ({
          url: `${tenantId}/connectors/${connectorType}/connections/${connectionId}/schedule/${scheduleId}/change_status`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnectionSchedules'],
      }),

      addTenantStream: builder.mutation<any, any>({
        query: ({ tenantId, payload }) => ({
          url: `${tenantId}/connectors/${payload[0]?.connector_type}/bulk-add-streams`,
          method: 'POST',
          body: { stream_list: payload },
        }),
        invalidatesTags: ['GetConnectorStreams'],
      }),
      updateTenantStream: builder.mutation<any, any>({
        query: ({ tenantId, payload }) => ({
          url: `${tenantId}/connectors/${payload?.connector_type}/update-stream`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetConnectorStreams'],
      }),
      deleteTenantStream: builder.mutation<any, any>({
        query: ({ tenantId, connectorType, stream, deployment_type }) => ({
          url: `${tenantId}/connectors/${connectorType}/delete-stream/${stream}`,
          method: 'DELETE',
          params: { deployment_type },
        }),
        invalidatesTags: ['GetConnectorStreams'],
      }),
      getAllTenants: builder.query<any, any>({
        query: () => ({
          url: 'api/monitoring/tenants',
        }),
        providesTags: ['GetMonitoringTenants'],
      }),
      getTenantData: builder.query<any, string>({
        query: () => ({
          url: `api/monitoring/data`,
        }),
        providesTags: ['GetMonitoringTenantsData']
      }),
      getIgnoredData: builder.query<any, string>({
        query: (hash) => ({
          url: `api/monitoring/data/${hash}`,
        }),
        providesTags: ['GetIgnoredData']
      }),
    }),
  });

export const defaultBaseApi = generateBaseApi(
  () => new Promise((resolve) => resolve('')),
);