import { Box, SelectChangeEvent, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { TaskData } from 'store/apis/types';
import PaginationComponent from 'components/pagination/Pagination';

export function Tasks() {
  const { tenant, connection, type } = useParams();
  const ApiProvider = useContext(ApiContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsData, setRowsData] = useState<TaskData[] | undefined>();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: tasksData,
    isLoading,
    isError,
    refetch,
  } = ApiProvider.baseApi?.useGetConnectionTasksQuery({
    tenantId: tenantHash,
    connectorType: type,
    connectionId: connection,
  });

  useEffect(() => {
    setRowsData(() => tasksData?.data);
    setTotalPage(Number(tasksData?.total));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksData]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getConnectionTasksData] =
    ApiProvider.baseApi?.useLazyGetConnectionTasksQuery();

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const data = await getConnectionTasksData({
      tenantId: tenantHash,
      connectorType: type,
      connectionId: connection,
      params: {
        items_page: pageEntryTotal,
        page: pageNo,
      },
    });
    setRowsData(() => data?.data?.data);
    setTotalPage(Number(data?.data?.total));
  };

  const columns: GridColDef[] = [
    {
      field: 'task_id',
      headerName: 'Task ID',
      width: 270,
    },
    {
      field: 'schedule_id',
      headerName: 'Schedule ID',
      width: 300,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      editable: true,
    },
    {
      field: 'triggered_at',
      headerName: 'Triggered At',
      width: 220,
      editable: true,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.triggered_at).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}
          </Box>
        );
      },
    },
    {
      field: 'stopped_at',
      headerName: 'Stopped At',
      width: 220,
      editable: true,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.triggered_at).format(
              'MMMM Do YYYY, h:mm:ss a',
            )}
          </Box>
        );
      },
    },
    {
      field: 'stopped_reason',
      headerName: 'Stopped Reason',
      width: 270,
      editable: true,
    },
    {
      field: 'task_url',
      headerName: 'Task URL',
      width: 420,
      editable: true,
      renderCell: (params) => {
        return (
          <Link href={params?.row?.task_url} rel="noopener" target="_blank">
            {params?.row?.task_url}
          </Link>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {rowsData?.length ? (
        <Box sx={{ height: '71vh', width: '81vw' }}>
          <DataGrid
            rows={rowsData.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            columns={columns}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
    </ContainerWithLoader>
  );
}
