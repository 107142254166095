import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ScheduleData } from 'store/apis/types';

export function ScheduleStreams() {
  const { tenant, connection, type, schedule } = useParams();
  const ApiProvider = useContext(ApiContext);

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';
  let currentSchedule: ScheduleData = {
    connection_id: '',
    connector_type: '',
    cron_string: '',
    schedule_id: '',
    stream_list: undefined,
  };

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: schedulesData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetConnectionSchedulesQuery({
    tenantId: tenantHash,
    connectorType: type,
    connectionId: connection,
  });

  if (schedulesData?.data) {
    currentSchedule = schedulesData?.data.find(
      (item: any) => item?.schedule_id === schedule,
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'stream',
      headerName: 'Name',
      width: 240,
    },
    {
      field: 'tier',
      headerName: 'Tier',
      width: 240,
      editable: true,
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {currentSchedule?.stream_list?.length ? (
        <Box sx={{ height: '71vh', width: '100%' }}>
          <DataGrid
            rows={currentSchedule?.stream_list.map(
              (item: any, index: number) => {
                return { ...item, id: index + 1 };
              },
            )}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
              sorting: {
                sortModel: [{ field: 'tier', sort: 'asc' }]
              }
            }}
            pageSizeOptions={[10, 20, 50]}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />
        </Box>
      ) : null}
    </ContainerWithLoader>
  );
}
