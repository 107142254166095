import React, { useContext } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { navItems } from './constants';
import { paths } from 'constants/routes';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { TenantData } from 'store/apis/types';
import { useKeycloak } from '@react-keycloak/web';

export const ListComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ApiProvider = useContext(ApiContext);
  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();
  const { keycloak } = useKeycloak();

  const currentOrg = tenantData?.data.find(
    (tenant: TenantData) => tenant?.hash === localStorage.getItem('currentOrg'),
  );

  return (
    <List>
      {navItems.map((item) => (
        <ListItem
          key={item.id}
          onClick={() => {
            if (paths[item?.path] === '/logout') keycloak.logout();
            else if (paths[item?.path] === '/tenants')
              navigate(paths[item?.path])
            else if (paths[item?.path] === '/tenant/:tenant')
              navigate(
                paths[item.path].split('/')[1] + '/' + currentOrg?.tenant_name,
              );
            else if (
              paths[item?.path] === '/versions' ||
              paths[item?.path] === '/streams'
            )
              navigate(paths[item?.path]);
            else
              navigate(
                paths[item.path].split('/')[1] +
                '/' +
                currentOrg?.tenant_name +
                '/' +
                paths[item.path].split('/')[3],
              );
          }}
          sx={{
            p: 0,
            backgroundColor:
              pathname === paths[item.path] ? '#c3c3c3' : 'transparent',
          }}
        >
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 40 }}>{item?.icon()}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
