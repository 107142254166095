import { useState, useContext } from 'react';
import { Box, Button, Dialog, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConnectionDialog } from './connection-dialog/connection-dialog';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { generateBaseConnection } from 'store/hooks';
import { DeletionDialog } from 'components/delete-modal/delete-modal';

export function Connections() {
  const { tenant, type } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [connection, setConnection] = useState({
    ...generateBaseConnection(),
    connector_type: type!,
  });

  const ApiProvider = useContext(ApiContext);

  const [createConnection] = ApiProvider.baseApi?.useAddConnectionMutation();

  const [updateConnection] = ApiProvider.baseApi?.useUpdateConnectionMutation();

  const [deleteConnection] = ApiProvider.baseApi?.useDeleteConnectionMutation();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const deleteDialog = (params: any) => {
    setConnection(params);
    setDeleteOpen(true);
  };

  const deleteClick = async () => {
    await deleteConnection({
      tenantId: tenantHash,
      connectorType: connection.connector_type,
      connectionId: connection.connector_id,
    });
    setDeleteOpen(false);
  };

  const {
    data: connectorsData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetConnectionsQuery({
    tenantId: tenantHash,
    connectorType: type,
  });

  const { data: secretsData } = ApiProvider.baseApi?.useGetTenantSecretsQuery({
    tenantId: tenantHash,
  });

  const addConnection = () => {
    setConnection({ ...generateBaseConnection(), connector_type: type! });
    setOpen(true);
  };

  const editConnection = (params: any) => {
    setConnection(params);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const showHistory = (params: any) => {
    navigate(`/tenant/${tenant}/${type}/${params.connector_id}/history`);
  };

  const showSchedules = (params: any) => {
    navigate(`/tenant/${tenant}/${type}/${params.connector_id}/schedules/${params.deployment_type}`);
  };

  const showTasks = (params: any) => {
    navigate(`/tenant/${tenant}/${type}/${params.connector_id}/tasks`);
  };

  const submitDetails = async () => {
    if (connection?.secret_value) {
      if (!connection?.id) {
        const res = await createConnection({
          tenantId: tenantHash,
          payload: { ...connection },
        });
        console.log('connection add response', res);
      } else {
        const res = await updateConnection({
          tenantId: tenantHash,
          connectorType: type,
          connectionId: connection?.connector_id,
          payload: { ...connection },
        });
        console.log('connection edit response', res);
      }
      handleDialogClose();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'connector_id',
      headerName: 'Name',
      width: 240,
      editable: true,
    },
    {
      field: 'destination_url',
      headerName: 'Destination',
      width: 300,
      editable: true,
    },
    {
      field: 'deployment_type',
      headerName: 'Deployment Type',
      width: 130,
    },
    {
      field: 'credentials_type',
      headerName: 'Credentials Type',
      width: 220,
    },
    {
      field: 'server_type',
      headerName: 'Server Type',
      width: 140,
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Tooltip title="Edit Connection">
              <EditIcon
                color="primary"
                sx={{ cursor: 'pointer' }}
                onClick={() => editConnection(params.row)}
              />
            </Tooltip>
            <Tooltip title="Delete Connection">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => deleteDialog(params.row)}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'history',
      headerName: 'History',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() => showHistory(params.row)}
            />
          </Box>
        );
      },
    },
    {
      field: 'schedule',
      headerName: 'Schedules',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() => showSchedules(params.row)}
            />
          </Box>
        );
      },
    },
    {
      field: 'task',
      headerName: 'Tasks',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() => showTasks(params.row)}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {/*<Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={addConnection}
        sx={{ mb: 2, float: 'right' }}
      >
        Add Connection
      </Button> */}
      <Box sx={{ height: '71vh', width: '81vw' }}>
        <DataGrid
          rows={connectorsData?.data?.map((item: any, index: number) => {
            return { ...item, id: index + 1 };
          })}
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          sx={{ background: '#fff', width: '100%' }}
        />
        <Dialog open={open} maxWidth="lg" fullWidth onClose={handleDialogClose}>
          <ConnectionDialog
            setOpen={setOpen}
            connection={connection!}
            setConnection={setConnection}
            submitDetails={submitDetails}
            secretsData={secretsData?.data}
          />
        </Dialog>
        <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
          <DeletionDialog
            setOpen={() => setDeleteOpen(false)}
            deleteItem={connection?.connector_id}
            submitDelete={deleteClick}
          />
        </Dialog>
      </Box>
    </ContainerWithLoader>
  );
}
