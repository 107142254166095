import EditIcon from '@mui/icons-material/Edit';
import { useContext, useEffect, useState } from 'react';
import {
  Box,
  SelectChangeEvent,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import PaginationComponent from 'components/pagination/Pagination';
import { StreamData } from 'store/apis/types';

export function Versions() {
  const ApiProvider = useContext(ApiContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [editVersion, setEditVersion] = useState(false);
  const [editRowData, setEditRowData] = useState<any>();
  const [connectorVersions, setConnectorVersions] = useState<any[]>([]);
  const [rowsData, setRowsData] = useState<StreamData[] | undefined>();

  const [getGlobalVersions] =
    ApiProvider.baseApi?.useLazyGetGlobalConnectorVersionsQuery();

  const [getVersions] = ApiProvider.baseApi?.useLazyGetConnectorVersionsQuery();

  const [updateAllConnectorVersions] =
    ApiProvider.baseApi?.useUpdateAllConnectorVersionsMutation();

  const getConnectorVersions = async (connectorType: string) => {
    const response = await getVersions({
      connectorType: connectorType,
    });
    setConnectorVersions(() => response?.data?.data);
  };

  useEffect(() => {
    onFilterChange(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const filteredData = await getGlobalVersions({
      items_page: pageEntryTotal,
      page: pageNo,
    });
    setRowsData(() => filteredData?.data?.data);
    if (!totalPage) setTotalPage(Number(filteredData?.data?.total));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Connector Type',
      width: 360,
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 290,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params) => {
        return (
          <Box display="flex" width="100%" justifyContent="space-around">
            <Tooltip title="Trigger">
              <EditIcon
                color="primary"
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  setEditRowData(params?.row);
                  setEditVersion(true);
                  await getConnectorVersions(params?.row?.name);
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const updateGlobalVersion = async () => {
    try {
      const res = await updateAllConnectorVersions({
        connectorType: editRowData?.name,
        version: editRowData?.version,
      });
      console.log('update connector version status', res);
    } catch (err) {
      console.log(err);
    }
    setEditVersion(false);
  };

  return (
    <ContainerWithLoader isLoading={!rowsData} isError={!rowsData}>
      {rowsData?.length ? (
        <Box sx={{ height: '71vh', width: '100%' }}>
          <DataGrid
            rows={rowsData?.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            columns={columns}

            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
      <Dialog open={editVersion} onClose={() => setEditVersion(false)}>
        <DialogContent>
          <DialogContentText>Please select the version</DialogContentText>
          <FormControl size="small" fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label-1">Version</InputLabel>

            <Select
              labelId="demo-simple-select-label-1"
              label="Version"
              value={editRowData?.version}
              onChange={(e) => {
                setEditRowData((prev: any) => ({
                  ...prev,
                  version: e.target.value,
                }));
              }}
            >
              {connectorVersions.map((src: any) => {
                return (
                  <MenuItem key={src} value={src}>
                    {src}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditVersion(false)} color="error">
            Cancel
          </Button>
          <Button onClick={updateGlobalVersion} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerWithLoader>
  );
}
