import { useAppSelector } from 'store/hooks';

interface TableState {
  checked: boolean[];
  stream: { id: string; frequency: string }[];
  initialStream: { id: string; frequency: string }[];
}

export const useTableDataSelector = (): TableState =>
  useAppSelector(({ tables }) => tables);
