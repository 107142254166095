import * as React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, useNavigate } from 'react-router';
import './breadcrumbs.css';

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname.split('/').filter((x) => x);

  const getReformatedName = (str: string) => {
    const reformatedName = str.replace(/%20/g, ' ');
    const words = reformatedName.split('-');
    let result = '';
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (i === 0) {
        result +=
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
      } else {
        result += word.toLowerCase() + ' ';
      }
    }
    return result.trim();
  };

  const path = location.pathname;
  return (
    <MUIBreadcrumbs
      sx={{ mb: 2 }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link
        color="inherit"
        underline="hover"
        className="hoverable"
        onClick={() => {
          navigate(path);
        }}
      >
        <HomeIcon fontSize="small" className="breadcrumb-icon" />
      </Link>
      {pathname.map((name, index) => {
        return (
          <Link
            color="inherit"
            underline="hover"
            className="hoverable"
            key={index}
            onClick={() => {
              let route = '';
              if (index === 0 || index === 3 || index === 5) {
                route = pathname.slice(0, index + 2).join('/');
              } else {
                route = pathname.slice(0, index + 1).join('/');
              }
              navigate(route);
            }}
          >
            {getReformatedName(name)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
