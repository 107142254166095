import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';

interface EditConnector {
  state: string;
  connector_type: string;
  connector_version: string;
}

interface Props {
  tenantHash: string;
  connectorTypes: string[];
  submitUpdate: () => void;
  updateItem: EditConnector;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentConnector: React.Dispatch<React.SetStateAction<EditConnector>>;
  isNewConnector: boolean;
  setIsNewConnector: any;
}

export function EditConnectorDialog({
  setOpen,
  tenantHash,
  updateItem,
  submitUpdate,
  connectorTypes,
  setCurrentConnector,
  isNewConnector,
  setIsNewConnector,
}: Props) {
  const ApiProvider = useContext(ApiContext);

  const [typesList, setTypesList] = useState<string[] | undefined>();

  const [getVersions] = ApiProvider.baseApi?.useLazyGetConnectorVersionsQuery();

  useEffect(() => {
    getConnectorVersions(updateItem?.connector_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItem?.connector_type]);

  const getConnectorVersions = async (connectorType: string) => {
    const response = await getVersions({
      tenantId: tenantHash || '',
      connectorType: connectorType,
    });
    setTypesList(() => response?.data?.data);
  };

  return (
    <ContainerWithLoader isLoading={false} isError={false}>
      <DialogTitle>
        Update Connector <b>{updateItem?.connector_type}</b> Details
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <FormControl sx={{ mt: 1 }} fullWidth>
            <InputLabel id="demo-simple-select-label-1">
              Connector Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-1"
              label="Connector Type"
              value={updateItem?.connector_type}
              disabled={!isNewConnector}
              onChange={(e) => {
                setCurrentConnector((prev: any) => ({
                  ...prev,
                  connector_type: e.target.value,
                }));
              }}
            >
              {connectorTypes.map((src: any) => {
                return (
                  <MenuItem key={src} value={src}>
                    {src}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* {!updateItem?.connector_type ? (
            <TextField
              label="Connector Type"
              sx={{ mt: 2 }}
              value={updateItem?.connector_type}
              onChange={(e) => {
                setCurrentConnector((prev: any) => ({
                  ...prev,
                  connector_type: e.target.value,
                }));
              }}
            />
          ) : null} */}
          {/* <TextField
            label="Connector Version"
            sx={{ mt: 2 }}
            value={updateItem?.connector_version}
            onChange={(e) => {
              setCurrentConnector((prev: any) => ({
                ...prev,
                connector_version: e.target.value,
              }));
            }}
          /> */}
          {typesList?.length ? (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label-1">Version</InputLabel>
              <Select
                labelId="demo-simple-select-label-1"
                label="Connector Version"
                value={updateItem?.connector_version}
                onChange={(e) => {
                  setCurrentConnector((prev: any) => ({
                    ...prev,
                    connector_version: e.target.value,
                  }));
                }}
              >
                {typesList?.map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsNewConnector(false);
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={submitUpdate} autoFocus>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </ContainerWithLoader>
  );
}
