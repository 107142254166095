import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Switch,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { StreamDialog } from './stream-dialog/stream-dialog';
import PaginationComponent from 'components/pagination/Pagination';
import { StreamData } from 'store/apis/types';
import { ConfirmationDialog } from 'components/confirmation-modal/confirmation-modal';

export function Streams() {
  const { tenant } = useParams();
  const ApiProvider = useContext(ApiContext);
  const generateBaseStream = () => {
    return {
      connector_type: '',
      deployment_type: '',
      params: '',
      stream: '',
      tier: 1,
      disabled: true,
    };
  };

  const [tier, setTier] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [deployment, setDeployment] = useState('');
  const [connectorType, setConnectorType] = useState('');
  const [stream, setStream] = useState(generateBaseStream());
  const [rowsData, setRowsData] = useState<StreamData[] | undefined>();

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  const [addTenantStream] = ApiProvider.baseApi?.useAddTenantStreamMutation();

  const [updateTenantStream] =
    ApiProvider.baseApi?.useUpdateTenantStreamMutation();

  const [getTenantStream] = ApiProvider.baseApi?.useLazyGetTenantStreamsQuery();

  const [replaceStreams] =
    ApiProvider.baseApi?.useReplaceGlobalStreamMutation();

  const [deleteTenantStream] =
    ApiProvider.baseApi?.useDeleteTenantStreamMutation();

  const [removeTenantStreams] =
    ApiProvider.baseApi?.useDisableTenantStreamsMutation();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: connectorsData,
    // isLoading: connectorLoading,
    // isError: connectorError,
  } = ApiProvider.baseApi?.useGetTenantConnectorTypesQuery(tenantHash || '');

  const editStream = (params: any) => {
    setStream(params);
    setOpen(true);
  };

  const replaceGlobalStreams = async () => {
    try {
      const params: any = {};
      if (connectorType) params.connector_type = connectorType;
      if (deployment) params.deployment_type = deployment;
      if (tier) params.tier_filter = tier;
      const res = await replaceStreams({ tenantId: tenantHash, params });
      console.log(res, 'replace streams response');
      setConfirm(false);
      pageChange(null, 1);
      onFilterChange(1, 10);
    } catch (err) {
      console.log(err, 'replace streams error');
    }
  };

  const disableAllTenantStreams = async () => {
    try {
      const params: any = {};
      if (connectorType) params.connector_type = connectorType;
      if (deployment) params.deployment_type = deployment;
      if (tier) params.tier_filter = tier;
      const res = await removeTenantStreams({ tenantId: tenantHash, params });
      console.log(res, 'disable streams response');
      setConfirm(false);
      pageChange(null, 1);
      onFilterChange(1, 10);
    } catch (err) {
      console.log(err, 'disable streams error');
    }
  };

  const submitDetails = async () => {
    if (stream?.connector_type && stream?.stream && stream?.tier) {
      const res = await updateTenantStream({
        tenantId: tenantHash,
        payload: {
          ...stream,
          params: stream.params.length ? stream.params.split(',') : [],
        },
      });
      console.log('stream edit response', res);
      setOpen(false);
    }
  };

  useEffect(() => {
    pageChange(null, 1);
    onFilterChange(1, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorType, tenantHash, tier, deployment, status]);

  const pageChange = (
    event: React.ChangeEvent<unknown> | null,
    value: number,
  ) => {
    setPage(value);
    onFilterChange(value, pageSize);
  };

  const handlePageSizeChange = async (event: SelectChangeEvent<number>) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    pageChange(null, 1);
    onFilterChange(1, Number(event.target?.value));
  };

  const onFilterChange = async (pageNo: number, pageEntryTotal: number) => {
    const params: any = {
      items_page: pageEntryTotal,
      page: pageNo,
    };
    if (connectorType) params.connector_type = connectorType;
    if (deployment) params.deployment_type = deployment;
    if (tier) params.tier_filter = tier;
    if (status && status === 'Enabled') params.disabled = false;
    if (status && status === 'Disabled') params.disabled = true;
    const tenantStreams = await getTenantStream({
      tenantId: tenantHash,
      params,
    });
    setRowsData(() => tenantStreams?.data?.data);
    setTotalPage(Number(tenantStreams?.data?.total));
  };

  const disableTenantStream = async () => {
    await deleteTenantStream({
      tenantId: tenantHash,
      connectorType: stream.connector_type,
      stream: stream.stream,
      deployment_type: stream.deployment_type,
    });
    setConfirm(false);
    pageChange(null, 1);
    onFilterChange(1, 10);
  };

  const enableTenantStream = async () => {
    await addTenantStream({
      tenantId: tenantHash,
      payload: [
        {
          ...stream,
          params:
            typeof stream.params === 'string'
              ? stream.params.split(',')
              : stream.params,
        },
      ],
    });
    setConfirm(false);
    pageChange(null, 1);
    onFilterChange(1, 10);
  };

  const submitConfirm = () => {
    if (message.slice(-15) === 'tenant streams?') {
      disableAllTenantStreams();
    } else if (message.slice(-15) === 'global streams?') {
      replaceGlobalStreams();
    } else {
      !stream?.disabled ? enableTenantStream() : disableTenantStream();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'stream',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'disabled',
      headerName: 'Enabled',
      sortable: false,
      width: 70,
      renderCell: (params) => {
        return (
          <Switch
            checked={!params.row.disabled}
            onChange={(e) => {
              const currentStatus = { ...params?.row };
              currentStatus.disabled = !e.target.checked;
              setStream(currentStatus);
              setMessage(
                `Do you want ${e.target.checked ? 'enable' : 'disable'} ${currentStatus.stream
                }?`,
              );
              setConfirm(true);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
    },
    {
      field: 'tier',
      headerName: 'Tier',
      sortable: false,
      width: 50,
    },
    {
      field: 'params',
      headerName: 'Params',
      width: 240,
      renderCell: (params) => {
        return <Box>{params?.row?.params[0]}</Box>;
      },
    },
    {
      field: 'connector_type',
      headerName: 'Connector Type',
      width: 180,
      editable: true,
    },
    {
      field: 'deployment_type',
      headerName: 'Deployment Type',
      width: 130,
    },
    {
      field: 'agent_id',
      headerName: 'Edit',
      width: 90
    },
  ];

  return (
    <ContainerWithLoader isLoading={!rowsData} isError={!rowsData}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" gap={1}>
          <Box width="200px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">
                Connector Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-1"
                label="Connector Type"
                value={connectorType}
                onChange={(e) => {
                  setConnectorType(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {connectorsData?.data
                  // .map((item: { name: string[] }) => item?.name[0])
                  .map((src: any) => {
                    return (
                      <MenuItem key={src} value={src}>
                        {src}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box width="80px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">Tier</InputLabel>
              <Select
                labelId="tisser-101"
                label="Tier"
                value={tier}
                onChange={(e) => {
                  setTier(Number(e.target.value) || 0);
                }}
              >
                <MenuItem value={0}>None</MenuItem>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width="200px">
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-select-label-1">
                Deployment Type
              </InputLabel>
              <Select
                labelId="dpelotment-1"
                label="Deployment Type"
                value={deployment}
                onChange={(e) => {
                  setDeployment(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {['Server', 'Cloud'].map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box width="120px">
            <FormControl size="small" fullWidth>
              <InputLabel id="stadjjf">Status</InputLabel>
              <Select
                labelId="status-1"
                label="Status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {['Enabled', 'Disabled'].map((src: any) => {
                  return (
                    <MenuItem key={src} value={src}>
                      {src}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setMessage(`Do you want to add these global streams?`);
              setConfirm(true);
            }}
          >
            Enable All
          </Button>
          <Button
            variant="outlined"
            startIcon={<RemoveIcon />}
            onClick={() => {
              setMessage(`Do you want to remove these tenant streams?`);
              setConfirm(true);
            }}
          >
            Disable All
          </Button>
        </Box>
      </Box>
      {rowsData?.length ? (
        <Box sx={{ height: '62vh', width: '100%' }}>
          <DataGrid
            rows={rowsData?.map((item: any, index: number) => {
              return { ...item, id: index + 1 };
            })}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            sx={{ background: '#fff', width: '100%' }}
          />

        </Box>
      ) : null}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <StreamDialog
          setOpen={setOpen}
          connection={stream!}
          setConnection={setStream}
          connectorTypes={connectorsData?.data}
          submitDetails={submitDetails}
        />
      </Dialog>
      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <ConfirmationDialog
          setOpen={() => setConfirm(false)}
          submitFunction={submitConfirm}
          message={message}
        />
      </Dialog>
    </ContainerWithLoader>
  );
}
