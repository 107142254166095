import React from 'react';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

interface Props {
  page: number;
  pageChange: (event: any, value: any) => void;
  count: number;
  pageSize: number;
  handlePageSizeChange: (event: any) => Promise<void>;
}

export default function PaginationComponent(props: Props) {
  const { count, page, pageSize, pageChange, handlePageSizeChange } = props;
  const pageSizeOptions = [10, 25, 50];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="40px"
    >
      <Box display="flex" alignItems="flex-start" height="40px">
        <Pagination
          color="primary"
          count={count}
          page={page}
          onChange={(event, value) => pageChange(event, value)}
          shape="rounded"
        />
      </Box>

      {pageSize && (
        <FormControl style={{ minWidth: '120px' }} size="small">
          <InputLabel id="pagesize-label">Cards per page</InputLabel>

          <Select
            labelId="pagesize-label"
            label="Cards per page"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {pageSizeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}
