import { useContext, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { paths } from 'constants/routes';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import EditIcon from '@mui/icons-material/Edit';
import { ConnectorData } from 'store/apis/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditConnectorDialog } from './edit-connector/edit-connector';
import { DeletionDialog } from 'components/delete-modal/delete-modal';

const emptyConnector = {
  connector_type: '',
  connector_version: '',
  state: '',
};

export function Connectors() {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const ApiProvider = useContext(ApiContext);

  const [openEdit, setOpenEdit] = useState(false);
  const [selectBy, setSelectBy] = useState("cloud");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editVersion, setEditVersion] = useState(false);
  const [currentConnector, setCurrentConnector] = useState(emptyConnector);
  const [editRowData, setEditRowData] = useState<ConnectorData | undefined>();
  const [isNewConector, setIsNewConnector] = useState(false);

  const { data: tenantData } = ApiProvider.baseApi?.useGetTenantsQuery();

  const [addConnector] = ApiProvider.baseApi?.useAddConnectorMutation();

  const [updateConnector] = ApiProvider.baseApi?.useUpdateConnectorMutation();

  const [deleteConnector] = ApiProvider.baseApi?.useDeleteConnectorMutation();

  let tenantHash = '';

  if (tenantData?.data) {
    tenantHash = tenantData?.data.find(
      (item: any) => item?.tenant_name === tenant,
    )?.hash;
  }

  const {
    data: connectorsData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetTenantConnectorsQuery({ "tenantId": tenantHash || '', "agent_id": selectBy || '' });

  const [getData] = ApiProvider.baseApi.useLazyGetTenantConnectorsQuery();

  const { data: agentData } = ApiProvider.baseApi?.useGetTenantVersionQuery(
    tenantHash || '',
  );

  const {
    data: tenantAgentVersions,
    // isLoading: tenantVersionLoad,
    // isError: tenantVersionError,
  } = ApiProvider.baseApi?.useGetAgentVersionsQuery(tenantHash || '');
  console.log(agentData);
  const {
    data: connectorTypes,
    // isLoading: connectorTypesLoading,
    // isError: connectorTypesError,
  } = ApiProvider.baseApi?.useGetGlobalConnectorTypesQuery('');

  const [updateTenantVersion] =
    ApiProvider.baseApi?.useUpdateTenantVersionMutation();

  const updateVersionOfTenant = async () => {
    const res = await updateTenantVersion({
      tenantId: tenantHash,
      version: editRowData?.connector_version,
      agent_id: selectBy
    });
    setEditVersion(false);
    console.log('version update tenant', res);
  };

  const submitDetails = async () => {
    if (Object.keys(currentConnector)?.length < 4) {
      const res = await addConnector({
        tenantId: tenantHash,
        payload: currentConnector,
      });
      console.log('edit connector status', res);
    } else {
      const res = await updateConnector({
        tenantId: tenantHash,
        payload: currentConnector,
      });
      console.log('edit connector status', res);
    }
    setOpenEdit(false);
  };

  const editConnector = (connectorData: any, newConnector: boolean) => {
    setCurrentConnector(connectorData);
    if (newConnector) {
      setIsNewConnector(() => true);
    }
    setOpenEdit(true);
  };

  const deleteDialog = (params: any) => {
    setCurrentConnector(params);
    setDeleteOpen(true);
  };

  const deleteClick = async () => {
    await deleteConnector({
      tenantId: tenantHash,
      connectorType: currentConnector.connector_type,
    });
    setDeleteOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'connector_type',
      headerName: 'Name',
      width: 100,
      editable: true,
    },
    {
      field: 'connector_version',
      headerName: 'Version',
      width: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Typography>{params.row.connector_version}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'aws_s3_url',
      headerName: 'AWS URL',
      editable: true,
      flex: 1,
    },
    {
      field: 'state',
      headerName: 'State',
      sortable: false,
      width: 100,
    },
    {
      field: 'agent_id',
      headerName: 'Connections',
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <ArrowOutwardIcon
              color="primary"
              sx={{ cursor: 'pointer' }}
              onClick={() =>
                navigate(
                  `/${paths.connectors.split('/')[1]}/${tenant}/${params.row.connector_type
                  }`,
                )
              }
            />
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Tooltip title="Edit Connection">
              <EditIcon
                color="primary"
                sx={{ cursor: 'pointer' }}
                onClick={() => editConnector(params.row, false)}
              />
            </Tooltip>
            <Tooltip title="Delete Connection">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => deleteDialog(params.row)}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {/* <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => editConnector(emptyConnector, true)}
        sx={{ mb: 2, float: 'right' }}
      >
        Add Connector
  </Button> */}

      <Box width="100%" display="flex" mb={1}>
        <Typography variant="subtitle1" mr={2}>
          Tenant Hash: {tenantHash}
        </Typography>

        <FormControl sx={{ minWidth: 200 }} >
          <InputLabel id="selectby-inut">Tenant Agent</InputLabel>
          <Select
            labelId="by-label"
            label="Tenant Agent"
            value={selectBy}
            size="small"
            onChange={async (e) => {
              setSelectBy(e.target.value)
              await getData({ "tenantId": tenantHash, "agent_id": selectBy })
            }
            }
          >
            <MenuItem value={"cloud"}>Cloud</MenuItem>
            <MenuItem value={"onprem"}>Onprem</MenuItem>
          </Select>
        </FormControl>

        {agentData?.[selectBy] === undefined ? (
          <Typography variant="subtitle1">
            Tenant Agent has not been deployed
          </Typography>
        ) : (
          <Typography variant="subtitle1">
            Tenant Agent Version : {agentData?.[selectBy]?.version}
          </Typography>
        )}
        <EditIcon
          color="primary"
          sx={{ cursor: 'pointer', height: '16px' }}
          onClick={() => {
            setEditRowData(connectorsData?.data[0]);
            setEditVersion(true);
          }}
        />
      </Box>
      <Box sx={{ height: '71vh', width: '100%' }}>
        <DataGrid
          rows={connectorsData?.data.map((item: any, index: number) => {
            return { ...item, id: index + 1 };
          })}
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          sx={{ background: '#fff', width: '100%' }}
        />
      </Box>
      <Dialog open={editVersion} onClose={() => setEditVersion(false)}>
        <DialogContent>
          <DialogContentText>Please select the version</DialogContentText>
          <FormControl size="small" fullWidth sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label-1">Version</InputLabel>

            <Select
              labelId="demo-simple-select-label-1"
              label="Version"
              value={editRowData?.connector_version}
              onChange={(e) => {
                setEditRowData((prev: any) => ({
                  ...prev,
                  connector_version: e.target.value,
                }));
              }}
            >
              {tenantAgentVersions?.data.map((src: any) => {
                return (
                  <MenuItem key={src} value={src}>
                    {src}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditVersion(false)} color="error">
            Cancel
          </Button>
          <Button onClick={updateVersionOfTenant} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEdit}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setIsNewConnector(false);
          setOpenEdit(false);
        }}
      >
        <EditConnectorDialog
          setOpen={setOpenEdit}
          tenantHash={tenantHash}
          updateItem={currentConnector}
          connectorTypes={connectorTypes?.data}
          setCurrentConnector={setCurrentConnector}
          submitUpdate={submitDetails}
          isNewConnector={isNewConector}
          setIsNewConnector={setIsNewConnector}
        />
      </Dialog>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DeletionDialog
          setOpen={() => setDeleteOpen(false)}
          deleteItem={currentConnector?.connector_type}
          submitDelete={deleteClick}
        />
      </Dialog>
    </ContainerWithLoader>
  );
}
