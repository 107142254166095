import { useContext, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ContainerWithLoader } from 'components/container-with-loader/container-with-loader';
import { TenantData } from 'store/apis/types';

const emptyTenantData = {
  auth0_tenant: '',
  aws_account: '',
  hash: localStorage.getItem('currentOrg') || '',
  inverted_proxy_url: '',
  tenant_URL: '',
  tenant_name: '',
};

export const Tenants = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ApiProvider = useContext(ApiContext);
  const {
    data: tenantData,
    isLoading,
    isError,
  } = ApiProvider.baseApi?.useGetTenantsQuery();

  const [organization, setOrganization] = useState<TenantData>(emptyTenantData);

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      {location?.pathname !== '/versions' &&
      location?.pathname !== '/streams' ? (
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-simple-select-label-1">Organization</InputLabel>

          <Select
            labelId="demo-simple-select-label-1"
            label="Organization"
            value={organization?.hash}
            onChange={(e) => {
              setOrganization(() =>
                tenantData?.data.find(
                  (tenant: TenantData) => tenant?.hash === e.target.value,
                ),
              );
              const currentOrg = tenantData?.data.find(
                (tenant: TenantData) => tenant?.hash === e.target.value,
              );
              localStorage.setItem('currentOrg', currentOrg.hash);
              navigate(
                `/${paths.connectors.split('/')[1]}/${currentOrg.tenant_name}`,
              );
            }}
          >
            {tenantData?.data.map((src: any) => {
              return (
                <MenuItem key={src.hash} value={src.hash}>
                  {src?.tenant_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </ContainerWithLoader>
  );
};
