import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitDetails: () => void;
  connection: any;
  setConnection: any;
  secretsData: any[];
}

const validateJSON = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

export function ConnectionDialog({
  setOpen,
  submitDetails,
  setConnection,
  connection,
  secretsData,
}: Props) {
  const [secretDropdown, setSecretDropdown] = useState(
    connection.secret_value ? true : false,
  );

  return (
    <Box>
      <DialogTitle>Connection Details</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Connector Type"
          sx={{ mt: 1 }}
          value={connection?.connector_type?.toLowerCase()}
          onChange={(e) => {
            setConnection((prev: any) => ({
              ...prev,
              connector_type: e.target.value,
            }));
          }}
        />
        <TextField
          label="Connector ID"
          sx={{ mt: 1 }}
          value={connection.connector_id}
          onChange={(e) => {
            setConnection((prev: any) => ({
              ...prev,
              connector_id: e.target.value,
            }));
          }}
        />
        <TextField
          label="Destination URL"
          sx={{ mt: 1 }}
          value={connection.destination_url}
          onChange={(e) => {
            setConnection((prev: any) => ({
              ...prev,
              destination_url: e.target.value,
            }));
          }}
        />
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label">
            Credentials Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            label="Credentials Type"
            value={connection.credentials_type}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                credentials_type: e.target.value,
              }));
            }}
          >
            {['AWS_SECRETS_MANAGER', 'FILE'].map((src, index) => {
              return (
                <MenuItem key={src} value={src}>
                  {src.split('_').join(' ')}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label-1">
            Deployment Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-1"
            label="Deployment Type"
            value={connection.deployment_type}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                deployment_type: e.target.value,
              }));
            }}
          >
            {['Server', 'Cloud'].map((src, index) => {
              return (
                <MenuItem key={src} value={src.toUpperCase()}>
                  {src}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label-2">Server Type</InputLabel>
          <Select
            labelId="demo-simple-select-label-2"
            label="Server Type"
            value={connection.server_type}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                server_type: e.target.value,
              }));
            }}
          >
            {['Private', 'Public'].map((src, index) => {
              return (
                <MenuItem key={src} value={src.toUpperCase()}>
                  {src}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box>
          <TextField
            label="App ID"
            sx={{ mt: 2 }}
            value={connection.app_id}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                app_id: e.target.value,
              }));
            }}
          />
          <TextField
            label="Owner"
            sx={{ mt: 2, ml: 2 }}
            value={connection.owner}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                owner: e.target.value,
              }));
            }}
          />
          {/* <TextField
            label="Secret Type"
            sx={{ mt: 2, ml: 2 }}
            value={connection.secret_type}
            onChange={(e) => {
              setConnection((prev: any) => ({
                ...prev,
                secret_type: e.target.value,
              }));
            }}
          /> */}
          {connection.credentials_type === 'AWS_SECRETS_MANAGER' ? (
            <>
              {/* <TextField
                label="Secret Name"
                sx={{ mt: 2 }}
                value={connection.secret_name}
                onChange={(e) => {
                  setConnection((prev: any) => ({
                    ...prev,
                    secret_name: e.target.value,
                  }));
                }}
              /> */}
              <TextField
                label="Region"
                sx={{ mt: 2, ml: 2 }}
                value={connection.region}
                onChange={(e) => {
                  setConnection((prev: any) => ({
                    ...prev,
                    region: e.target.value,
                  }));
                }}
              />
            </>
          ) : (
            ''
          )}
          <Box display="flex" alignItems="center" mt={1}>
            <FormControlLabel
              value="start"
              sx={{ ml: '2px' }}
              control={
                <Checkbox
                  checked={connection?.is_encrypted}
                  onChange={(e) => {
                    console.log('s', e.target.checked);
                    setConnection((prev: any) => ({
                      ...prev,
                      is_encrypted: e.target.checked,
                    }));
                  }}
                />
              }
              label="Encryption"
              labelPlacement="start"
            />
            <FormControlLabel
              value="Select Secret"
              sx={{ ml: '40px' }}
              control={
                <Checkbox
                  checked={secretDropdown}
                  onChange={(e) => {
                    setSecretDropdown(e.target.checked);
                  }}
                />
              }
              label="Select Secret"
              labelPlacement="start"
            />
          </Box>
        </Box>
        {!secretDropdown ? (
          <Box width="100%">
            <TextField
              label="Secret Value"
              sx={{ mt: 2 }}
              value={JSON.stringify(connection.secret_value)}
              multiline
              rows={3}
              fullWidth
              maxRows={12}
              required
              onChange={(e) => {
                if (validateJSON(e.target.value))
                  setConnection((prev: any) => ({
                    ...prev,
                    secret_value: JSON.parse(e.target.value),
                  }));
              }}
            />
          </Box>
        ) : (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="secret-selection-label">
              Secret Selection
            </InputLabel>
            <Select
              labelId="secret-selection"
              label="Secret Selection"
              value={connection.secret_value}
              onChange={(e) => {
                setConnection((prev: any) => ({
                  ...prev,
                  secret_value: e.target.value,
                }));
              }}
            >
              {secretsData.map((src, index) => {
                return (
                  <MenuItem key={src?.secret_name} value={src}>
                    {src.secret_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={2}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={submitDetails}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
}
