import { Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import { GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { initiateStore } from 'store/store';
import { generateBaseApi } from 'store/apis/base';
import { ApiProvider } from 'components/providers/api-provider/api-provider';
import { generateRoutingList } from 'constants/routes';
import { Navigation } from 'components/navigation/navigation';
import { Colors } from 'constants/colors';
import { theme } from 'utils/theme/theme';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import { Auth } from './components/auth/auth';

export function App() {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    console.log(keycloak.authenticated);
    console.log(initialized);
    console.log(keycloak.tokenParsed);
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(290);
    }
  }, [keycloak, initialized]);

  const getToken = async () => keycloak.token || '';
  const baseApi = generateBaseApi(getToken);
  const store = initiateStore(baseApi);

  const routes = generateRoutingList();

  const renderMainContent = () => {
    if (keycloak.authenticated) {
      return (
        <Suspense fallback={null}>
          <Routes>
            {routes.map((route) => {
              if (route.isRender) {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={route.element}
                  />
                );
              }
              return null;
            })}
          </Routes>
        </Suspense>
      );
    }
    return null;
  };

  return (
    <ApiProvider baseApi={baseApi}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Auth />
          <CssBaseline />
          <GlobalStyles
            styles={{ body: { '.MuiList-root': { backgroundColor: '#fff' } } }}
          />
          <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            {CustomGlobalStyles}
            <Navigation />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: Colors.tiber,
                padding: '20px 30px',
                marginLeft: { sm: '200px', xs: 0 },
                marginTop: '4em',
              }}
              data-testid="main-wrapper"
            >
              <Breadcrumbs />
              {renderMainContent()}
            </Box>
          </Box>
        </ThemeProvider>
      </Provider>
    </ApiProvider>
  );
}

const CustomGlobalStyles = (
  <GlobalStyles
    styles={{
      '.hide-resize-observer': {
        display: 'none !important',
      },
      '.MuiList-root': { backgroundColor: Colors.blackPearl },

      '.MuiPickersPopper-root': {
        '& .MuiPaper-root': {
          backgroundColor: Colors.blackPearl,
          color: Colors.white,
        },
      },
      '#dependencyTreeContainer': {
        path: {
          fill: 'none',
          stroke: '#919191',
        },

        text: {
          fontSize: '10px',
          fill: 'white',
        },
      },
      'input[data-autocompleted]': {
        'background-color': 'transparent !important;',
      },
      '.MuiCheckbox-root.Mui-checked': {
        color: '#5346F8 !important',
      },
    }}
  />
);
