import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

//The table filter name should contain page name + table name
export enum FiltersTableName {
  IDENTITIES_USERS_LIST = 'identities_users_list',
  ASSETS_REPOS_LIST = 'assets_repos_list',
  BRANCHES_LIST = 'branches_list',
  TEAMS_LIST = 'teams_list',
  IDENTITIES_ACCESS = 'identities_access',
  ASSETS_DETAILS_CONTRIBUTORS = 'assets_details_contributors',
  ASSETS_NESTED_BRANCHES = 'assets_nested_branches',
  OPENSOURCE_REPOSITORIES_LIST = 'opensource-repositories_list',
  DETECTED_SECRETS_LIST = 'detected_secrets_list',
  ENTITY_GRAPH = 'entity-graph',
  POLICY_BUILDER = 'policy-builder',
  POLICIES_LIBRARY = 'policies-library',
  POLICIES_CUSTOM_POLICIES = 'policies-custom-polices',
}

export type Filter = Record<
  string,
  string | boolean | string[] | undefined | number | any[]
>;

export type FiltersState = Record<FiltersTableName, Filter>;

const initialState: FiltersState = {
  [FiltersTableName.IDENTITIES_USERS_LIST]: {},
  [FiltersTableName.ASSETS_REPOS_LIST]: {},
  [FiltersTableName.IDENTITIES_ACCESS]: {},
  [FiltersTableName.ASSETS_DETAILS_CONTRIBUTORS]: {},
  [FiltersTableName.ASSETS_NESTED_BRANCHES]: {},
  [FiltersTableName.BRANCHES_LIST]: {
    only_important: true,
  },
  [FiltersTableName.TEAMS_LIST]: {},
  [FiltersTableName.OPENSOURCE_REPOSITORIES_LIST]: {},
  [FiltersTableName.DETECTED_SECRETS_LIST]: {},
  [FiltersTableName.ENTITY_GRAPH]: {},
  [FiltersTableName.POLICY_BUILDER]: {},
  [FiltersTableName.POLICIES_LIBRARY]: {},
  [FiltersTableName.POLICIES_CUSTOM_POLICIES]: {},
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersForTable: (
      state: FiltersState,
      action: PayloadAction<{
        tableName: FiltersTableName;
        filters: Filter;
      }>,
    ) => {
      state[action.payload.tableName] = action.payload.filters;
    },
  },
});

export const { setFiltersForTable } = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;
