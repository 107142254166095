import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';

import { addRoles } from 'store/modules/auth/auth.reducer';

export const Auth = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!keycloak.authenticated) return;
    const getClaims = async () => {
      try {
        // const roles = processClaims(keycloak?.realmAccess?.roles);
        dispatch(addRoles(keycloak?.realmAccess?.roles || []));
      } catch (error) {
        console.log('get claims error', error);
      }
    };
    getClaims();
  }, [keycloak, dispatch]);

  return null;
};
