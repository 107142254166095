import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const generateBaseConnection = () => {
  return {
    id: '',
    connector_type: '',
    app_id: '',
    is_encrypted: false,
    owner: 'Identity-Science',
    server_type: 'PRIVATE',
    connector_id: '',
    credentials_type: 'AWS_SECRETS_MANAGER',
    destination_url: '',
    region: '',
    secret_type: 'AWS_SECRETS_MANAGER',
    deployment_type: '',
    secret_value: undefined,
  };
};
