import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { streamsColumns } from './headers';
import { DataGrid } from '@mui/x-data-grid';
import { StreamData } from 'store/apis/types';
import { setDataForTable } from 'store/modules/filters/tables.reducer';
import { useTableDataSelector } from 'store/modules/filters/tables.selector';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSchedule: any;
  scheduleDetails: any;
  allStreams: StreamData[];
  submitDetails: () => void;
  tierSelection: number;
  setTierSelection: React.Dispatch<React.SetStateAction<number>>;
}

export function ScheduleDialog({
  setOpen,
  allStreams,
  setSchedule,
  scheduleDetails,
  submitDetails,
  tierSelection,
  setTierSelection,
}: Props) {
  const rows = allStreams.map((item: any, index: number) => {
    return {
      ...item,
      id: `${item.stream}-${item.tier}`,
      index: index,
    };
  });

  const dispatch = useDispatch();
  let tableDispatch = useTableDataSelector();
  const cronSelectOptions = [
    { value: '*/30 * * * ? *', label: 'Every 30 Mins' },
    { value: '0 * * * ? *', label: 'Every 1 Hour' },
    { value: '0 */2 * * ? *', label: 'Every 2 Hours' },
    { value: '0 */4 * * ? *', label: 'Every 4 Hours' },
    { value: '0 */6 * * ? *', label: 'Every 6 Hours' },
    { value: '0 */8 * * ? *', label: 'Every 8 Hours' },
    { value: '0 */12 * * ? *', label: 'Every 12 Hours' },
    { value: 'cron-expression', label: 'Cron Expression' },
  ];

  const [selectBy, setSelectBy] = useState(
    scheduleDetails?.tier ? 'tiers' : 'streams',
  );
  const [child, setChild] = useState(false);
  // const [tierSelection, setTierSelection] = useState(0);



  const [cronOptionType, setCronOptionType] = useState(
    scheduleDetails?.cron_string
      ? cronSelectOptions
        .map((option) => option?.value)
        .findIndex((item) => item === scheduleDetails?.cron_string) !== -1
        ? scheduleDetails?.cron_string
        : 'cron-expression'
      : '',
  );

  const checkValue = (selectTier: number) => {
    const payload = {
      checked: allStreams
        ?.filter((stream) => stream?.tier === selectTier)
        ?.map((streamItem) => true),
      stream: allStreams
        ?.filter((stream) => stream?.tier === selectTier)
        ?.map((streamItem) => {
          return {
            id: `${streamItem.stream}-${streamItem.tier}`,
            frequency: '',
          };
        }),
      initialStream: tableDispatch.initialStream,
    };
    dispatch(setDataForTable(payload));
  };

  const checkChildValues = () => {
    const childTiers = Array.from(Array(tierSelection + 1).keys());
    const payload = {
      checked: allStreams
        ?.filter(
          (stream) =>
            childTiers.findIndex((child) => child === stream?.tier) !== -1,
        )
        ?.map((streamItem) => true),
      stream: allStreams
        ?.filter(
          (stream) =>
            childTiers.findIndex((child) => child === stream?.tier) !== -1,
        )
        ?.map((streamItem) => {
          return {
            id: `${streamItem.stream}-${streamItem.tier}`,
            frequency: '',
          };
        }),
      initialStream: tableDispatch.initialStream,
    };
    dispatch(setDataForTable(payload));
  };

  const selectCronType = (cronValue: string) => {
    setCronOptionType(cronValue);
    if (cronValue !== 'cron-expression') {
      setSchedule((prev: any) => ({
        ...prev,
        cron_string: cronValue,
      }));
    } else {
      setSchedule((prev: any) => ({
        ...prev,
        cron_string: '',
      }));
    }
  };

  return (
    <Box>
      <DialogTitle>Schedule Details</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <FormControl sx={{ mt: 1 }} fullWidth>
            <TextField
              label="Schedule Name"
              sx={{ mt: 1.5 }}
              value={scheduleDetails?.schedule_name}
              required
              onChange={(e) => {
                setSchedule((prev: any) => ({
                  ...prev,
                  schedule_name: e.target.value,
                }));
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ mt: 1 }} fullWidth>
            <InputLabel id="cron-option">Cron Options</InputLabel>
            <Select
              labelId="cron-option-label"
              label="Cron Options"
              value={cronOptionType}
              onChange={(e) => selectCronType(e.target.value)}
            >
              {cronSelectOptions?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>{option?.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {cronOptionType === 'cron-expression' ? (
          <TextField
            label="Schedule Cron"
            sx={{ mt: 1.5 }}
            required
            value={scheduleDetails?.cron_string}
            onChange={(e) => {
              setSchedule((prev: any) => ({
                ...prev,
                cron_string: e.target.value,
              }));
            }}
          />
        ) : null}
        <Box>
          <FormControlLabel
            value="Run Analytics"
            sx={{ mt: '2px', ml: '2px' }}
            control={
              <Checkbox
                checked={scheduleDetails?.run_analytics}
                onChange={(e) => {
                  setSchedule((prev: any) => ({
                    ...prev,
                    run_analytics: e.target.checked,
                  }));
                }}
              />
            }
            label="Run Analytics"
            labelPlacement="start"
          />
        </Box>
        <Box>
          <FormControl sx={{ mt: 1.5 }} fullWidth>
            <InputLabel id="selectby-inut">Select By</InputLabel>
            <Select
              labelId="by-label"
              label="Select By"
              value={selectBy}
              onChange={(e) => setSelectBy(e.target.value)}
            >
              <MenuItem value="streams">Streams</MenuItem>
              <MenuItem value="tiers">Tiers</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {selectBy === 'tiers' ? (
          <Box>
            <Box>
              <FormControl sx={{ mt: 1 }} fullWidth>
                <InputLabel id="tier-selection-iput">Tier</InputLabel>
                <Select
                  labelId="tier-selection-iput"
                  label="Tier"
                  value={tierSelection}
                  onChange={(e) => {
                    checkValue(Number(e.target.value));
                    setTierSelection(Number(e.target.value));
                  }}
                >
                  <MenuItem value={999}>All</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* <Box display="flex" alignItems="center" mt={1}>
              <FormControlLabel
                value="start"
                sx={{ ml: '2px' }}
                control={
                  <Checkbox
                    checked={child}
                    onChange={(e) => {
                      setChild(e.target.checked);
                      if (e.target.checked) checkChildValues();
                    }}
                  />
                }
                label="Child Selection"
                labelPlacement="start"
              />
              </Box>*/}
          </Box>
        ) : (
          <DataGrid
            rows={rows}
            columns={streamsColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
              sorting: {
                sortModel: [{ field: 'tier', sort: 'asc' }],
              }
            }}
            pageSizeOptions={[20, 50, 100]}
            disableRowSelectionOnClick
            sx={{ minHeight: '60vh', mt: 2 }}
            autoHeight
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%" px={2}>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={submitDetails}
            disabled={
              !scheduleDetails.cron_string
            }
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
}
