import React, { useState } from 'react';
import { Drawer, AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

// import { getPath } from 'utils/nav/nav-utils';
import logo from 'assets/images/logo.png';
import { ListComponent } from './list';
import { Tenants } from './tenants';

export const Navigation = () => {
  const { pathname } = useLocation();
  // const currentPath = getPath(pathname);

  const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);

  const handleDrawerToggle = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ background: 'rgba(0, 0, 0, 0.31)' }}>
        <Toolbar sx={{ backgroundColor: '#fff', width: '100vw' }}>
          <Box display="flex" justifyContent="space-between" width="100vw">
            <Box>
              <IconButton
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 0, width: '50px', display: { sm: 'none' } }}
              >
                <img src={logo} height="25px" width="25px" />
              </IconButton>
            </Box>
            <Box width="320px">
              <Tenants />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <>
        <Drawer
          variant="temporary"
          open={isDrawerExpanded}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { marginTop: '3.5em' },
          }}
        >
          <ListComponent />
        </Drawer>
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
          PaperProps={{
            sx: { width: '200px' },
          }}
          variant="permanent"
          anchor="left"
        >
          <ListComponent />
        </Drawer>
      </>
    </Box>
  );
};
