import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface TableState {
  checked: boolean[];
  stream: { id: string; frequency: string }[];
  initialStream: { id: string; frequency: string }[];
}

const initialState: TableState = {
  checked: [],
  stream: [{ id: '', frequency: '' }],
  initialStream: [{ id: '', frequency: '' }],
};

export const tableSlice = createSlice({
  name: 'tableData',
  initialState,
  reducers: {
    setDataForTable: (state: TableState, action: PayloadAction<TableState>) =>
      action.payload,
  },
});

export const { setDataForTable } = tableSlice.actions;

export const tablesReducer = tableSlice.reducer;
