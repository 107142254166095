import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Tooltip, CircularProgress, Typography, TextField } from '@mui/material';
import { ApiContext } from 'components/providers/api-provider/api-provider';

export const MyTable = () => {
    const { tenantsData } = useParams();
    const navigate = useNavigate();
    const [tenants, setTenants] = useState([]);
    const [tenantDataMap, setTenantDataMap] = useState({});
    const [selectedTenantData, setSelectedTenantData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredTenants, setFilteredTenants] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [showTenantTable, setShowTenantTable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingIgnoredData, setLoadingIgnoredData] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [tenantSearchQuery, setTenantSearchQuery] = useState('');

    const ApiProvider = useContext(ApiContext);
    const [getAllTenants, { data: tenantData, error: tenantError, isLoading: tenantLoading }] = ApiProvider.baseApi?.useLazyGetAllTenantsQuery();
    const [getTenantData, { data: tenantDetails, error: tenantDetailsError }] = ApiProvider.baseApi?.useLazyGetTenantDataQuery();
    const [fetchIgnoredData, { data: ignoredData, error: ignoredDataError }] = ApiProvider.baseApi?.useLazyGetIgnoredDataQuery();

    useEffect(() => {
        fetchTenants();
    }, []);

    useEffect(() => {
        if (tenantData) {
            setTenants(tenantData);
            fetchAllTenantData(tenantData);
        }
    }, [tenantData]);

    useEffect(() => {
        if (tenantsData && tenantDataMap[tenantsData]) {
            setSelectedTenantData(tenantDataMap[tenantsData]);
            setIsDataFetched(true);
            setShowTenantTable(false);
        }
    }, [tenantsData, tenantDataMap]);

    useEffect(() => {
        if (tenantSearchQuery === '') {
            setFilteredTenants(tenants);
        } else {
            const filtered = tenants.filter(tenant => tenant.tenant_name.toLowerCase().includes(tenantSearchQuery.toLowerCase()));
            setFilteredTenants(filtered);
        }
    }, [tenantSearchQuery, tenants]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(selectedTenantData);
        } else {
            const filtered = selectedTenantData.filter(item => item.stream.toLowerCase().includes(searchQuery.toLowerCase()));
            setFilteredData(filtered);
        }
    }, [searchQuery, selectedTenantData]);

    const fetchTenants = () => {
        getAllTenants();
    };

    const fetchAllTenantData = async (tenants) => {
        try {
            const result = await getTenantData();
            setTenantDataMap(result.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching tenant data:', error);
            setLoading(false);
        }
    };

    const handleBackClick = () => {
        navigate('/tenants');
        setIsDataFetched(false);
        setShowTenantTable(true);
    };

    const handleTenantClick = async (hash) => {
        setLoadingIgnoredData(true);
        try {
            const ignoredResult = await fetchIgnoredData(hash);
            const currentData = tenantDataMap[hash] || [];
            const combinedData = currentData.concat(ignoredResult.data[hash]);

            setTenantDataMap(prevState => {
                return { ...prevState, [hash]: combinedData };
            });
            setSelectedTenantData(combinedData);
            setIsDataFetched(true);
            setShowTenantTable(false);
        } catch (error) {
            console.error('Error fetching ignored data:', error);
        } finally {
            setLoadingIgnoredData(false);
        }
    };

    const getHealthStatus = (tenantHash) => {
        const data = tenantDataMap[tenantHash] || [];
        if (data.length === 0) return 'white';
        return data.some(item => item.health_check === 'red') ? 'red' : 'green';
    };

    const getHealthTooltip = (tenantHash) => {
        const data = tenantDataMap[tenantHash] || [];
        const issues = data.filter(item => item.health_check === 'red');
        const issueStreams = issues.map(item => item.stream);
        return issueStreams.join(' | ');
    };

    const sortedTenants = [...filteredTenants].sort((a, b) => {
        const healthA = getHealthStatus(a.hash);
        const healthB = getHealthStatus(b.hash);
        if (healthA === 'red' && healthB !== 'red') return -1;
        if (healthA !== 'red' && healthB === 'red') return 1;
        return 0;
    });

    return (
        <div>
            {loading || loadingIgnoredData ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                    <CircularProgress />
                    <Typography variant="h6" gutterBottom>Loading Tenant Data</Typography>
                </Box>
            ) : (
                <>
                    {showTenantTable ? (
                        <>
                            <div>
                                <TextField
                                    label="Search Tenant Name"
                                    variant="outlined"
                                    value={tenantSearchQuery}
                                    onChange={(e) => setTenantSearchQuery(e.target.value)}
                                />
                            </div>

                            <TableContainer component={Paper} style={{ height: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tenant Name</TableCell>
                                            <TableCell>Health Status</TableCell>
                                            <TableCell>Tenant Hash</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedTenants.map((tenant, index) => (
                                            <TableRow key={index} onClick={() => handleTenantClick(tenant.hash)} style={{ cursor: 'pointer' }}>
                                                <TableCell>{tenant.tenant_name}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={getHealthTooltip(tenant.hash)} arrow>
                                                        <Box style={{ backgroundColor: getHealthStatus(tenant.hash), width: '20px', height: '20px' }} />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>{tenant.hash}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        isDataFetched && (
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                    <TextField
                                        label="Search Stream"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <Button onClick={handleBackClick} variant="contained" color="primary">
                                        Back
                                    </Button>
                                </Box>
                                <TableContainer component={Paper} style={{ marginTop: '20px', height: '400px', overflowY: 'auto', overflowX: 'auto' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Stream</TableCell>
                                                <TableCell>Collection Time</TableCell>
                                                <TableCell>Normalized Time</TableCell>
                                                <TableCell>Time Difference (Hours)</TableCell>
                                                <TableCell>Analytics Start</TableCell>
                                                <TableCell>Time Difference (Hours)</TableCell>
                                                <TableCell>Analytics End</TableCell>
                                                <TableCell>Time Difference (Hours)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {row.stream}
                                                        {row.health_check === 'red' && (
                                                            <Box component="span" style={{ backgroundColor: 'red', width: '10px', height: '10px', display: 'inline-block', marginLeft: '5px' }} />
                                                        )}
                                                    </TableCell>
                                                    <TableCell>{row.aws_time}</TableCell>
                                                    <TableCell>{row.mongo_time}</TableCell>
                                                    <TableCell>{row.time_diff}</TableCell>
                                                    <TableCell>{row.analytics_start}</TableCell>
                                                    <TableCell>{row.time_diff1}</TableCell>
                                                    <TableCell>{row.analytics_end}</TableCell>
                                                    <TableCell>{row.time_diff2}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default MyTable;