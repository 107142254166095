import { configureStore } from '@reduxjs/toolkit';
import { defaultBaseApi } from 'store/apis/base';
import { filtersReducer } from 'store/modules/filters/filters.reducer';
import { tablesReducer } from './modules/filters/tables.reducer';
import { authReducer } from 'store/modules/auth/auth.reducer';

export const initiateStore = (baseApi: typeof defaultBaseApi) => {
  const reducer = {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    filters: filtersReducer,
    tables: tablesReducer,
  };
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      if (baseApi) {
        return getDefaultMiddleware().concat([baseApi?.middleware]);
      }
      return getDefaultMiddleware();
    },

    devTools: process.env.NODE_ENV !== 'production',
  });
};

const defaultStore = initiateStore(defaultBaseApi);
export type RootState = ReturnType<typeof defaultStore.getState>;

export type AppDispatch = typeof defaultStore.dispatch;
