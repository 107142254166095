import { lazy } from 'react';

import { NotFound } from 'pages/not-found/not-found';
import { Connectors } from 'pages/connectors/connectors';
import { Connections } from 'pages/connectors/connections/connections';
import { ConnectionHistory } from 'pages/connectors/connections/history/connection-history';
import { ConnectionSchedules } from 'pages/connectors/connections/schedules/schedules';
import { ExecutionDetails } from 'pages/connectors/connections/history/execution-details/execution-details';
import { ScheduleStreams } from 'pages/connectors/connections/schedules/schedule-streams/schedule-streams';
import { Streams } from 'pages/streams/streams';
import { Tasks } from 'pages/connectors/connections/tasks/tasks';
import { Diagnostics } from 'pages/diagnostics/diagnostics';
import { Versions } from 'pages/versions/versions';
import { GlobalStreams } from 'pages/global-streams/global-streams';
import { MyTable } from 'pages/monitoring/monitoring-streams';

export const paths = {
  dashboard: '/',
  globalStreams: '/streams',
  streams: '/tenant/:tenant/streams',
  diagnostics: '/tenant/:tenant/diagnostics',
  connectors: '/tenant/:tenant',
  connections: '/tenant/:tenant/:type',
  versions: '/versions',
  tasks: '/tenant/:tenant/:type/:connection/tasks',
  schedule: '/tenant/:tenant/:type/:connection/schedules/:deployment_type',
  connectionHistory: '/tenant/:tenant/:type/:connection/history',
  executionDetails:
    '/tenant/:tenant/:type/:connection/history/:execution/details',
  scheduleStreams:
    '/tenant/:tenant/:type/:connection/schedules/:schedule/streams',
  tenants: '/tenants',
  tenantsData: '/tenants/data/:tenantsData',
  notFound: '*',
  logout: '/logout',
};

export const generateRoutingList = () => {
  const Dashboard = lazy(() =>
    import('pages/dashboard/dashboard').then((module) => ({
      default: module.Dashboard,
    })),
  );

  return [
    {
      id: 'dashboard',
      path: paths.dashboard,
      element: <Dashboard />,
      isRender: true,
    },
    {
      id: 'connectors',
      path: paths.connectors,
      element: <Connectors />,
      isRender: true,
    },
    {
      id: 'globalStreams',
      path: paths.globalStreams,
      element: <GlobalStreams />,
      isRender: true,
    },
    {
      id: 'streams',
      path: paths.streams,
      element: <Streams />,
      isRender: true,
    },
    {
      id: 'diagnostics',
      path: paths.diagnostics,
      element: <Diagnostics />,
      isRender: true,
    },
    {
      id: 'connections',
      path: paths.connections,
      element: <Connections />,
      isRender: true,
    },
    {
      id: 'versions',
      path: paths.versions,
      element: <Versions />,
      isRender: true,
    },
    {
      id: 'connectionHistory',
      path: paths.connectionHistory,
      element: <ConnectionHistory />,
      isRender: true,
    },
    {
      id: 'tasks',
      path: paths.tasks,
      element: <Tasks />,
      isRender: true,
    },
    {
      id: 'executionDetails',
      path: paths.executionDetails,
      element: <ExecutionDetails />,
      isRender: true,
    },
    {
      id: 'schedule',
      path: paths.schedule,
      element: <ConnectionSchedules />,
      isRender: true,
    },
    {
      id: 'scheduleStreams',
      path: paths.scheduleStreams,
      element: <ScheduleStreams />,
      isRender: true,
    },
    {
      id: 'tenants',
      path: paths.tenants,
      element: <MyTable />,
      isRender: true,
    },
    {
      id: 'tenantsData',
      path: paths.tenantsData,
      element: <MyTable />,
      isRender: true,
    },
    {
      id: 'not-found',
      path: paths.notFound,
      element: <NotFound />,
    },
  ];
};
